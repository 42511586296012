import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "../styles/MuiDataTableStyle";
import { createFinancer, editFinancer, getFinancers } from "../services/financersService";
import { useStateAuthValue } from "../context/AuthState";
import { useToast } from "../context/Toast/ToastProvider";
import { showToast, ToastType } from "../utils/ToastUtil";
import { LoadingModal } from "../components/Modals/LoadingModal";
import { Form, Formik } from "formik";
import { FormikField, SelectObjectField } from "../components/FormFields";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { FidgetSpinner } from "react-loader-spinner";
import { Circle } from "@mui/icons-material";
import { BaseModal } from "../components/Modals/BaseModal";

const EditFinancer = ({ open, setOpen, financerData, getFinancersData }) => {
  const [{ userToken }] = useStateAuthValue();
  const toastDispatch = useToast();
  const navigate = useNavigate();

  const displayError = (msg) =>
    showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);
  const [financer, setFinancer] = useState(financerData);

  const FormScheme = Yup.object().shape({
    financerName: Yup.string().required("No debe quedar vacío"),
    cuit: Yup.string().matches(/^[^a-zA-Z]*$/, 'Debe contener solo números y/o caracteres especiales').required("No debe quedar vacío"),
    businessName: Yup.string().required("No debe quedar vacío"),
    commercialAddress: Yup.string().required("No debe quedar vacío"),
    legalAddress: Yup.string().required("No debe quedar vacío"),
    phoneNumber: Yup.string().matches(/^[^a-zA-Z]*$/, 'Debe contener solo números y/o caracteres especiales').required("No debe quedar vacío"),
    email: Yup.string()
      .email("Ingrese un mail valido")
      .required("No debe quedar vacío"),
  });

  const [financerPlansList, setFinancerPlansList] = useState([]);

  const [currentFinancerPlan, setCurrentFinancerPlan] = useState({});

  const showError = (msgError) => {
    showToast(toastDispatch, msgError, ToastType.ERROR, ToastType.ERROR);
  };
  const formikSetValues = useRef(null);

  const handleEditFinancer = (values) => {
    editFinancer(userToken, values, displayError).then((e) => {
      if (e != null) {
        showToast(
          toastDispatch,
          "Se creo con exito",
          ToastType.SUCCESS,
          ToastType.SUCCESS
        );
      }
    });
  };
  useEffect(() => {
    if (financerData !== null && Object.keys(financerData).length !== 0) {
      console.log(financerData);
    }
  }, [financerData]);
  
  return (
    <BaseModal open={open} setOpen={setOpen} width="50rem">
      <h3>Editar Empresa</h3>
      <Formik
        onSubmit={(values) => {
          handleEditFinancer(values);
          setOpen(false);
          getFinancersData();
        }}
        initialValues={financerData}
        validationSchema={FormScheme}
      >
        {({ isValid, values, setValues, setFieldValue, errors }) => {
          formikSetValues.current = setValues;
          return (
            <Form>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  gap: "3rem",
                }}
              >
                <FormikField
                  name="financerName"
                  style={{ width: "20rem" }}
                  label="Empresa(Nombre Comercial)"
                />
                <FormikField
                  name="businessName"
                  style={{ width: "20rem" }}
                  label="Razon Social"
                />
                <FormikField
                  name="cuit"
                  style={{ width: "10rem" }}
                  label="CUIT"
                />
                <FormikField
                  name="commercialAddress"
                  style={{ width: "20rem" }}
                  label="Domicilio Comercial"
                />
                <FormikField
                  name="legalAddress"
                  style={{ width: "20rem" }}
                  label="Domicilio Legal"
                />
                <FormikField
                  name="phoneNumber"
                  style={{ width: "10rem" }}
                  label="Telefono"
                />
                <FormikField
                  name="email"
                  style={{ width: "10rem" }}
                  label="Correo"
                />
                <></>
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  disabled={Object.keys(errors).length !== 0}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#002c54",
                    margin: "30px 10px 0 0",
                    borderRadius: "20px",
                    texttransform: "capitalize",
                  }}
                >
                  Guardar
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </BaseModal>
  );
};

export default EditFinancer;
