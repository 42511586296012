const patientFormModel = {
   alias: {
      name: 'alias',
      label: 'Alias',
   },
   first_name: {
      name: 'first_name',
      label: 'Nombre',
      stringErrorMsg: 'Ingresa sólo letras',
      requiredErrorMsg: 'El nombre es requerido',
   },
   last_name: {
      name: 'last_name',
      label: 'Apellido',
      requiredErrorMsg: 'El apellido es requerido',
   },
   id_number: {
      name: 'id_number',
      label: 'DNI',
      minErrorMsg: 'Ingresa un DNI válido',
      maxErrorMsg: 'Ingresa un DNI válido',
      requiredErrorMsg: 'El DNI es requerido',
   },
   email: {
      name: 'email',
      label: 'Email',
      minErrorMsg: 'Ingresa una dirección de mail válida',
      maxErrorMsg: 'Ingresa una dirección de mail válida',
      requiredErrorMsg: 'El Email es requerido',
   },
   phone_number: {
      name: 'phone_number',
      label: 'Teléfono',
      minErrorMsg:
      'Ingresa un teléfono válido. Escribe el código de área sin 0 y el número sin 15. Ej. 1123456789',
      maxErrorMsg:
      'Ingresa un teléfono válido. Escribe el código de área sin 0 y el número sin 15. Ej. 1123456789',
      requiredErrorMsg: 'El teléfono es requerido',
   }
};

export default patientFormModel;
