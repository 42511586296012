import {makeStyles} from "@material-ui/core";

export const buttonRedStyles = makeStyles(() => ({
    submit: {
      backgroundColor: "#DA2037",
      "&:hover": {
        backgroundColor: "#e84353",
      },
      marginTop: "30px",
      borderRadius: "20px",
      texttransform: "capitalize",
    }
  }));

export const buttonBlueStyles = makeStyles(() => ({
    submit: {
      backgroundColor: "#002c54",
      "&:hover": {
        backgroundColor: "#0a0052",
      },
      borderRadius: "20px",
    }
  }));

export const loginStyles = makeStyles(() => ({
    submit: {
      backgroundColor: "#DA2037",
      "&:hover": {
        backgroundColor: "#e84353",
      },
      marginTop: "30px",
      borderRadius: "20px",
      texttransform: "capitalize",
    },
    visibility: {
      color: "#DA2037",
    },
    txtForgotPass: {
      margin: "2px",
      texttransform: "capitalize",
      width: "100%",
      alignSelf: "center",
      textAlign: "center",
    },
    title: {
      color: "#002c54",
      textAlign: "center",
      padding: "10px",
    },
    iconButton: {
      "&:focus": {
        outline: "none !important",
      },
    },
  }));