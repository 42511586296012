import React from "react";
import {
  InputField,
} from "../../components/FormFields";

const PersonalInformationPatientForm = ({ formModel, clientValues }) => {
  const {
    alias,
    first_name,
    last_name,
    id_number,
    phone_number,
    email,
  } = formModel;

  return (
    <div className="personal-info-form">
        <InputField
          name={alias.name}
          label={alias.label}
          className="field"
        />
          <InputField
            name={first_name.name}
            label={first_name.label}
            className="field"
            requiredField
          />
          <InputField
            name={last_name.name}
            label={last_name.label}
            className="field"
            requiredField
          />
          <InputField
            name={id_number.name}
            label={id_number.label}
            className="field"
            requiredField
          />
          <InputField
            name={phone_number.name}
            label={phone_number.label}
            extraLabel={phone_number.extraLabel}
            className="field"
            requiredField
          />
          <InputField
            name={email.name}
            label={email.label}
            extraLabel={email.extraLabel}
            className="field"
            requiredField
          />
    </div>
  );
};

export default PersonalInformationPatientForm;
