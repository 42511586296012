import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "../styles/MuiDataTableStyle";
import {
  createPatient,
  getFinancerPlans,
  getPatients,
  validarDniExiste,
  validarMailExiste,
  validatePatientExist,
} from "../services/patientsService";
import { useStateAuthValue } from "../context/AuthState";
import { useToast } from "../context/Toast/ToastProvider";
import { showToast, ToastType } from "../utils/ToastUtil";
import { LoadingModal } from "../components/Modals/LoadingModal";
import { SeePatientDetailsModal } from "../components/Modals/SeePatientDetailsModal";
import { Form, Formik } from "formik";
import { FormikField, SelectObjectField } from "../components/FormFields";
import * as Yup from "yup";
import {
  getPrepaidHealth,
  getPrepaidHealthCategoryByPrepaidHealth,
} from "../services/prepaidHealthService";
import { useNavigate } from "react-router-dom";
import { FidgetSpinner } from "react-loader-spinner";
import { Circle } from "@mui/icons-material";
import { BaseModal } from "../components/Modals/BaseModal";
import { validate } from "@material-ui/pickers";

const CreatePatient = ({ open, setOpen, getPatientsData }) => {
  const [{ userToken }] = useStateAuthValue();
  const toastDispatch = useToast();
  const navigate = useNavigate();

  const displayError = (msg) =>
    showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);
  const [patient, setPatient] = useState({
    firstName: "",
    lastName: "",
    id_number: "",
    financer_plan: null,
    email: "",
    sex:"",
  });
  const FormScheme = Yup.object().shape({
    firstName: Yup.string().required("No debe quedar vacío"),
    lastName: Yup.string().required("No debe quedar vacío"),
    id_number: Yup.number("El dni solo puede contener numeros")
      .min(999999, "El DNI debe tener minimo 7 digitos")
      .required("No debe quedar vacío"),
    financer_plan: Yup.string().required("No debe quedar vacío"),
    email: Yup.string().required("No debe quedar vacío"),
    sex: Yup.string().required("No debe quedar vacío"),
  });
  const [financerPlansList, setFinancerPlansList] = useState([]);

  const [currentFinancerPlan, setCurrentFinancerPlan] = useState({});
  const sexList = [
    {
      name: `Hombre`,
      id: `MALE`,
    },
    {
      name: `Mujer`,
      id: `FEMALE`,
    },
    {
      name: `No binario`,
      id: `NON_BINARY`,
    },
  ];
  const showError = (msgError) => {
    showToast(toastDispatch, msgError, ToastType.ERROR, ToastType.ERROR);
  };
  const formikSetValues = useRef(null);

  const fetchFinancerPlans = async () => {
    const response = await getFinancerPlans(userToken, showError);
    let _financerPlansList = [];
    console.log(response);
    response?.data?.map((plan) => {
      if (plan.financerId == "32333636-3631-3338-2d33-3536362d3336") {
        _financerPlansList = [
          ..._financerPlansList,
          {
            name: `${plan.planName}`,
            id: plan.id,
          },
        ];
      }
    });
    let _agreementFinancerPlansList = [];
    response?.data?.map((plan) => {
      if (plan.financerId != "32333636-3631-3338-2d33-3536362d3336") {
        _agreementFinancerPlansList = [
          ..._agreementFinancerPlansList,
          {
            name: `${plan.planName} - ${plan.financer.financerName}`,
            id: plan.id,
          },
        ];
      }
    });
    _agreementFinancerPlansList.sort((a, b) => {
      // Comparamos los nombres en minúsculas para asegurar una comparación insensible a mayúsculas/minúsculas
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (nameA < nameB) {
        return -1; // nameA debe ir antes que nameB
      }
      if (nameA > nameB) {
        return 1; // nameA debe ir después que nameB
      }
      return 0; // Los nombres son iguales
    });
    _financerPlansList = [
      ..._financerPlansList,
      ..._agreementFinancerPlansList,
    ];
    setFinancerPlansList(_financerPlansList);
  };

  useEffect(() => {
    fetchFinancerPlans();
  }, []);
  const [validation, setValidation] = useState({
    emailError: null,
    dniError: null,
  });
  const handleCreatePatient = async (values) => {
    values.financer_plan = currentFinancerPlan.id;
    await validatePatientExist(userToken, values.email, values.id_number).then(
      async (response) => {
        console.log(response);
        if (response.documento != "Ok") {
          showToast(
            toastDispatch,
            "DNI " + response.documento,
            ToastType.ERROR,
            ToastType.ERROR
          );
        }
        if (response.mail != "Ok") {
          showToast(
            toastDispatch,
            "Correo " + response.mail,
            ToastType.ERROR,
            ToastType.ERROR
          );
        }
        setValidation({
          dniError: response.documento != "Ok" && "DNI " + response.documento,
          emailError: response.mail != "Ok" && "Correo " + response.mail,
        });
        if (response.documento == "Ok" && response.mail == "Ok") {
          await createPatient(userToken, values, displayError).then((e) => {
            if (e != null) {
              console.log("createPatient: ",e);
              
              showToast(
                toastDispatch,
                "Se creo con exito",
                ToastType.SUCCESS,
                ToastType.SUCCESS
              );
              setOpen(false);
            }
          });

        }
      }
    );
  };
  return (
    <BaseModal open={open} setOpen={setOpen}>
      <h3>Crear nuevo usuario</h3>
      <Formik
        onSubmit={(values) => {
          handleCreatePatient(values);
          getPatientsData();
        }}
        initialValues={patient}
        validationSchema={FormScheme}
      >
        {({
          isValid,
          values,
          setValues,
          setFieldValue,
          errors,
          validateForm,
        }) => {
          formikSetValues.current = setValues;
          return (
            <Form>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2,1fr)",
                  gridGap: "25px",
                }}
              >
                <FormikField name="lastName" label="Apellido" />
                <FormikField name="firstName" label="Nombre" />
                <FormikField
                  name="id_number"
                  errorMsg={validation.dniError}
                  label="DNI"
                  type="number"
                  onFocus={() => {
                    setValidation({ ...validation, dniError: null });
                  }}
                />
                <FormikField
                  name="email"
                  label="Correo"
                  errorMsg={validation.emailError}
                  onFocus={() => {
                    setValidation({ ...validation, emailError: null });
                  }}
                />
                <SelectObjectField
                  label="Plan"
                  setValue={(e) => {
                    setValues({ ...values, financer_plan: e.id });
                    setCurrentFinancerPlan(e);
                  }}
                  value={currentFinancerPlan}
                  data={financerPlansList || []}
                  className="field"
                  errorMsg={errors.financer_plan}
                  isError={errors.financer_plan}
                />
                <SelectObjectField
                    label="Sexo"
                    setValue={(e) => {
                      //console.log(financerPlansList.filter((fp) => fp.id == values.plan_id)[0]);
                      setValues({ ...values, sex: e.id });
                      //setCurrentFinancerPlan(e);
                    }}
                    value={
                      sexList.filter(
                        (fp) => fp.id == values.sex
                      )[0]
                    }
                    data={sexList}
                  className="field"
                  errorMsg={errors.sex}
                  isError={errors.sex}
                    //errorMsg={errors.financer_plan}
                    //isError={errors.financer_plan}
                  />
                {/*
                    <SelectObjectField
                      label="Plan"
                      defaultValue=""
                      setValue={setPrepaidHealthCategory}
                      value={prepaidHealthCategory}
                      data={prepaidHealthCategoryList || []}
                      className="field"
                      disabled={
                        !prepaidHealth || emptyPrepaidHealthCategoryList
                      }
                      extraLabel={renderExtraLabel()}
                    /> */}
                {/* <FormikField
                      name="affiliate_number"
                      label="N° de asociado"
                    /> */}
                <></>
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  disabled={
                    Object.keys(errors).length != 0 ||
                    validation.dniError ||
                    validation.dniError
                  }
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#002c54",
                    margin: "30px 10px 0 0",
                    borderRadius: "20px",
                    texttransform: "capitalize",
                  }}
                >
                  Guardar
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </BaseModal>
  );
};

export default CreatePatient;
