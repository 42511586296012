import DoneIcon from "@mui/icons-material/Done";
import { Button } from "@material-ui/core";

const PlanTable = ({handleClick}) => {

  return (
    <div>
      <div className="plan-table-container center-flex">
        <table>
          <thead>
            <tr>
              <th>
                <p>Servicios que brinda la plataforma</p>
              </th>
              <th>
                <p>CLASSIC</p>
              </th>
              <th>
                <p>PLUS</p>
              </th>
              <th>
                <p>PREMIUM</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p style={{ paddingLeft: "9px" }}>
                  Disponibilidad del servicio 24 horas todos los días
                </p>
              </td>
              <td>
                <DoneIcon />
              </td>
              <td>
                <DoneIcon />
              </td>
              <td>
                <DoneIcon />
              </td>
            </tr>
            <tr>
              <td>
                <p style={{ paddingLeft: "9px" }}>
                  Receta electrónica de medicamentos (excepto psicofármacos)
                </p>
              </td>
              <td>
                <DoneIcon />
              </td>
              <td>
                <DoneIcon />
              </td>
              <td>
                <DoneIcon />
              </td>
            </tr>
            <tr>
              <td>
                <p style={{ paddingLeft: "9px" }}>
                  Constancias de atención médica y certificados laborales
                </p>
              </td>
              <td>
                <DoneIcon />
              </td>
              <td>
                <DoneIcon />
              </td>
              <td>
                <DoneIcon />
              </td>
            </tr>
            <tr>
              <td>
                <p style={{ paddingLeft: "9px" }}>
                  Prescripción de estudios complementarios
                </p>
              </td>
              <td>
                <DoneIcon />
              </td>
              <td>
                <DoneIcon />
              </td>
              <td>
                <DoneIcon />
              </td>
            </tr>
            <tr>
              <td>
                <p style={{ paddingLeft: "9px" }}>Derivación a especialistas</p>
              </td>
              <td>
                <DoneIcon />
              </td>
              <td>
                <DoneIcon />
              </td>
              <td>
                <DoneIcon />
              </td>
            </tr>
            <tr>
              <td>
                <p style={{ paddingLeft: "9px" }}>
                  Notificaciones dentro de la plataforma y vía correo
                  electrónico
                </p>
              </td>
              <td>
                <DoneIcon />
              </td>
              <td>
                <DoneIcon />
              </td>
              <td>
                <DoneIcon />
              </td>
            </tr>
            <tr>
              <td>
                <p style={{ paddingLeft: "9px" }}>
                  Historia clínica del paciente
                </p>
              </td>
              <td>
                <DoneIcon />
              </td>
              <td>
                <DoneIcon />
              </td>
              <td>
                <DoneIcon />
              </td>
            </tr>
            <tr>
              <td>
                <p style={{ paddingLeft: "9px" }}>
                  Consultas por videollamadas al mes
                </p>
              </td>
              <td>1</td>
              <td>2</td>
              <td>4</td>
            </tr>
            <tr>
              <td>
                <p style={{ paddingLeft: "9px" }}>
                  Especialidad Clínica Médica
                </p>
              </td>
              <td>
                <DoneIcon />
              </td>
              <td>
                <DoneIcon />
              </td>
              <td>
                <DoneIcon />
              </td>
            </tr>
            <tr>
              <td>
                <p style={{ paddingLeft: "9px" }}>Especialidad Pediatría</p>
              </td>
              <td></td>
              <td>
                <DoneIcon />
              </td>
              <td>
                <DoneIcon />
              </td>
            </tr>
            <tr>
              <td>
                <p style={{ paddingLeft: "9px" }}>
                  Adhesión de miembros del grupo familiar
                </p>
              </td>
              <td></td>
              <td>
                <DoneIcon />
              </td>
              <td>
                <DoneIcon />
              </td>
            </tr>
            <tr>
              <td>
                <p style={{ paddingLeft: "9px" }}>PRECIO x MES - TITULAR</p>
              </td>
              <td>
                <p className="price">
                  $2.300<span>/mes</span>
                </p>
              </td>
              <td>
                <p className="price">
                  $3.600<span>/mes</span>
                </p>
              </td>
              <td>
                <p className="price">
                  $6.000<span>/mes</span>
                </p>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"  
                  onClick={() => handleClick(1)}                
                >
                  Contratar
                </Button>
              </td>
              <td>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => handleClick(2)}                
                >
                  Contratar
                </Button>
              </td>
              <td>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => handleClick(3)}                
                >
                  Contratar
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PlanTable;
