import { useState } from "react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "../../styles/MuiDataTableStyle";
import { Box, Fade, PaginationItem, Popper, Toolbar } from "@mui/material";
import TablePagination from "@material-ui/core/TablePagination";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import FeedIcon from "@mui/icons-material/Feed";
import { textLabels, downloadOptions } from "../../utils/muiTableOptions";
import { SeeFinancerPlanDetailsModal } from "../Modals/SeeFinancerPlanDetailsModal";
import { useEffect } from "react";
import {
  disableFinancerPlan,
  editFinancerPlan,
  activateFinancerPlan,
} from "../../services/financerPlansService";
import { useStateAuthValue } from "../../context/AuthState";
import { useToast } from "../../context/Toast/ToastProvider";
import { showToast, ToastType } from "../../utils/ToastUtil";
import { convertDateFormat } from "../../utils/dateForm";
import { editPayment } from "../../services/PaymentService";
import CustomToolbar from "./CustomTollbar";
import { NewUserTollbar } from "./NewUserTollbar";
import CreateFinancerPlan from "../../pages/createFinancerPlan";

export const FinancerPlanTable = ({
  data,
  getFinancerPlansData,
  setLoading,
  totalPages,
  setTotalPages,
  dataLength,
  currentPage,
}) => {
  const [{ userToken }] = useStateAuthValue();
  const toastDispatch = useToast();
  const [finalData, setFinalData] = useState(data);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openCreateNewModal, setOpenCreateNewModal] = useState(false);
  const [openInactivationModal, setOpenInactivationModal] = useState(false);
  const [user, setUser] = useState({});
  const [textSearch, setTextSearch] = useState("");
  const [reason, setReason] = useState("");

  useEffect(() => {
    setFinalData(data);
  }, [data]);

  const displayError = (msg) =>
    showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);

  const finishEditFinancerPlan = async (object) => {
    setOpenDetailsModal(false);
    setLoading(true);

    const newData = {
      ...user,
      ...object,
    };

    editFinancerPlan(userToken, newData, displayError).finally(() => {
      setLoading(false);
    });

    setLoading(false);
    setUser({});
    setTimeout(() => {
      getFinancerPlansData();
    }, 1000);
  };

  const activateDisabledFinancerPlan = async (object) => {
    setLoading(true);

    activateFinancerPlan(userToken, object, displayError).finally(() => {
      setLoading(false);
    });

    setTimeout(() => {
      getFinancerPlansData();
    }, 1000);
  };

  const columns = [
    {
      name: "planName",
      label: "Nombre de plan",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "consultationsPerMonth",
      label: "Consultas por mes",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "familyMembers",
      label: "Cantidad de miembros",
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: "price",
      label: "Precio",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "priceMember",
      label: "Precio por miembro adicional",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "financer.financerName",
      label: "Empresa financiadora",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "agreement.description",
      label: "Convenio",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "acciones",
      label: "Acciones",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div className="icons-container">
              <EditIcon
                id={finalData[dataIndex].usrId}
                className="icon edit-icon"
                onClick={() => {
                  setUser(finalData[dataIndex]);
                  setOpenDetailsModal(true);
                }}
              />
            </div>
          );
        },
      },
    },
  ];

  const options = {
    searchOpen: true,
    selectableRows: "none",
    textLabels,
    downloadOptions,
    filterType: "dropdown",
    responsive: "standard",
    draggableColumns: { enabled: true },
    enableNestedDataAccess: ".",
    page: currentPage,
    count: totalPages,
    serverSide: true,
    pagination: false,
    elevation: 0,
    onSearchChange: (searchQuery) => {
      getFinancerPlansData({ query: searchQuery });
    },
    onTableChange: (action, tableState) => {
      if (action === "search") {
        setTextSearch(tableState.searchText);
      }
    },
    customToolbar: () => {
      return (
        <NewUserTollbar
          onClickFunc={() => {
            setOpenCreateNewModal(true);
          }}
        />
      );
    },
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <Box
        sx={{
          width: "100%",
          "& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-head": {
            span: {
              justifyContent: "center",
            },
          },
        }}
      >
        <MUIDataTable
          title=""
          data={finalData}
          columns={columns}
          options={options}
        />
        <TablePagination
          component="div"
          count={totalPages}
          page={currentPage}
          onPageChange={(state, number) => {
            getFinancerPlansData({ query: textSearch, page: number });
          }}
          labelRowsPerPage=""
          rowsPerPage={dataLength}
          rowsPerPageOptions={[dataLength]}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`
          }
        />
        <CreateFinancerPlan
          open={openCreateNewModal}
          setOpen={setOpenCreateNewModal}
          getFinancerPlansData={() => {
            setLoading(true);
            setTimeout(() => {
              getFinancerPlansData();
              setLoading(false);
            }, 2000);
          }}
        />
        <SeeFinancerPlanDetailsModal
          open={openDetailsModal}
          setOpen={setOpenDetailsModal}
          handleClose={finishEditFinancerPlan}
          financerPlan={user}
        />
      </Box>
    </ThemeProvider>
  );
};
