import axios from "axios";

const plansUrl = `${process.env.REACT_APP_BASE_URL}/services/parametrics/api/plans/backoffice`;
const plansBaseUrl = `${process.env.REACT_APP_BASE_URL}/services/parametrics/api/plans`;

export const getPlans = async (token, displayError = () => {}, params) => {
  let url = `${plansUrl}${params?.query || params?.page ? "?" : ""}${
    params?.query && params?.query !== "" ? `search=` + params?.query : ``
  }${params?.query && params?.page ? `&` : ""}${
    params?.page ? `page=` + params?.page : ``
  }`;
  return await axios
    .get(url, { headers: { authorization: `Bearer ${token}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("getPlans error: " + error.message);
    });
};

export const editPlan = async (token, data, displayError = () => {}) => {
  return await axios
    .put(`${plansBaseUrl}/${data?.id}`, data, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("editPlan error: " + error.message);
    });
};

export const activatePlan = async (token, data, displayError = () => {}) => {
  return await axios
    .post(
      `${plansUrl}/${data?.id}`,
      { ...data, activated: true },
      { headers: { authorization: `Bearer ${token}` } }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("activatePlan error: " + error.message);
    });
};

export const disablePlan = async (
  token,
  id,
  reason,
  displayError = () => {}
) => {
  return await axios
    .delete(`${plansUrl}/${id}/${reason}`, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("disablePlan error: " + error.message);
    });
};

export const createPlan = async (token, data, displayError = () => {}) => {
  return await axios
    .post(`${plansBaseUrl}`, data, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("createPlan error: " + error.message);
    });
};

export const getAllPlans = async (token, displayError = () => {}, params) => {
  let url = `${plansBaseUrl}`;
  return await axios
    .get(url, { headers: { authorization: `Bearer ${token}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("getAllAgreements error: " + error.message);
    });
};
