import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/styles.scss";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import MedilineTheme from "./theme/MedilineTheme";
import reportWebVitals from "./reportWebVitals";
import { AuthState } from "./context/AuthState";
import { ReportsState } from "./context/Reports";
import ToastProvider from "./context/Toast/ToastProvider";
import { ProSidebarProvider } from "react-pro-sidebar";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={MedilineTheme}>
      <AuthState>
        <ReportsState>
          <ToastProvider>
            <CssBaseline />
            <ProSidebarProvider>
              <App />
            </ProSidebarProvider>
          </ToastProvider>
        </ReportsState>
      </AuthState>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
