import { useState, useEffect, Fragment } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TablePagination from '@mui/material/TablePagination';
import { ThemeProvider } from '@emotion/react';
import { MuiTheme } from '../styles/MuiDataTableStyle';
import { getSocialWork, getSocialWorkCategory, postSocialWork, postSocialWorkCategory, putSocialWork, putSocialWorkCategory } from '../services/socialWork';
import { useStateAuthValue } from '../context/AuthState';
import { ToastType, showToast } from '../utils/ToastUtil';
import { useToast } from '../context/Toast/ToastProvider';
import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { SocialWorkDetailsModal } from '../components/Modals/SocialWorkDetailsModal';
import EditIcon from '@mui/icons-material/Edit';

export default function CollapsibleTable() {
  const [{ userToken }] = useStateAuthValue();
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [socialWork, setSocialWork] = useState('');
  const toastDispatch = useToast();
  const displayError = (msg) => showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);

  const GetSocialWork = async () => {
    try {
      const response = await getSocialWork(userToken, displayError);
      const filteredData = response.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setData(filteredData);
    } finally {
      setLoading(false);
    }
  };

  const GetSocialWorkCategory = async (id) => {
    try {
      const response = await getSocialWorkCategory(userToken, displayError, id);
      return response
    } catch {
      return [];
    } 
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    GetSocialWork();
  }, [userToken]);

  useEffect(() => {
    if (searchTerm !== ''){
      GetSocialWork();
      setPage(0);
    }
  }, [searchTerm]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const finishEditSocialWork = async (object) => {
    let responseSocialWork = null;
    if (userToken) {
      try {
        if (object.id !== null){
          responseSocialWork = await putSocialWork(userToken, displayError, {id: object.id, name: object.name}, object.id);
        } else {
          responseSocialWork = await postSocialWork(userToken, displayError, {name: object.name});
        }
        if(responseSocialWork){
          let responseSocialWorkCategory = null;
          object.plan.map(async (element) => {
            if (element.name === '') element.name = 'Basico';
            if (element.id !== null){
              responseSocialWorkCategory = await putSocialWorkCategory(userToken, displayError, {id: element.id, name: element.name, prepaidHealth: responseSocialWork}, element.id);
            } else{
              responseSocialWorkCategory = await postSocialWorkCategory(userToken, displayError, {name: element.name, prepaidHealth: responseSocialWork});
            }
            return responseSocialWorkCategory;
          })
        }
      } finally {
        GetSocialWork();
        setLoading(false);
        setOpenDetailsModal(false);
      }
    }
  }
  

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const [category, setCategoty] = useState([]);

    const handleEdit = async (row) => {
      let category = await GetSocialWorkCategory(row.id);
      let socialWork = {id: row.id || null, name: row.name, plan: category};
      setSocialWork(socialWork);
      setOpenDetailsModal(true);
    };
  
    const handleOpen = async (row) => {
      setOpen(!open)
      if (!open) {
        let category = await GetSocialWorkCategory(row.id);
        setCategoty(category);
      }
    };

    return (
      <Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleOpen(row)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.name}
          </TableCell>
          <TableCell>
            <IconButton onClick={() => handleEdit(row)}>
              <EditIcon></EditIcon>
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Planes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {category.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell align='center'>{row?.name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }
  const add = (
    <div style={{position: 'absolute', right: '20px'}}>
      <IconButton onClick={() => {
        setSocialWork('');
        setOpenDetailsModal(true);
      }}>
        <AddIcon></AddIcon>
      </IconButton>
    </div>
  );
  const search = (
    <div style={{position: 'relative', left: '20px'}}>
        <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <TextField id="input-with-sx" variant="standard" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>
    </div>
  );

  const table = (
    <TableContainer component={Paper}>
      <div style={{position: 'relative', display: 'flex'}}>
        {search}
        {add}
      </div>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Nombre</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => (
              <Row key={row.name} row={row} />
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );

  return (
    <Fragment>
      <SocialWorkDetailsModal
        open={openDetailsModal}
        setOpen={setOpenDetailsModal}
        handleClose={finishEditSocialWork}
        socialWork={socialWork}
      />
      {!loading && (
        <ThemeProvider theme={MuiTheme}>
          <div className="users">
            <div className="users-table-container">
              <Box
                sx={{
                  background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                  padding: "10px",
                  borderRadius: "15px",
                  color: "white",
                  height: "80px",
                  display: "flex",
                  alignItems: "center",
                  width: "90%",
                  position: "relative",
                  bottom: "25px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: " 700",
                    letterSpacing: " 1px",
                  }}
                >
                  Listado de Obras Sociales
                </Typography>
              </Box>
              {table}
            </div>
          </div>
        </ThemeProvider>
      )}
    </Fragment>
  );
}