import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Formik, Form } from "formik";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { BaseModal } from "./BaseModal";
import axios from "axios";
export const InactivatePatientModal = ({
  open,
  setOpen,
  handleClose,
  patient,
  setReason,
  reason,
}) => {
  const [reasons, setReasons] = useState([])
  const getUnsubscribeReason = async (token, dni) => {
    return await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/services/parametrics/api/unsubscribe-reasons`
      )
      .then((response) => {
        setReasons(response.data)
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };
  useEffect(() => {
    getUnsubscribeReason();
  }, [])


  return (
    <BaseModal
      width="650px"
      open={open}
      setOpen={() => {
        setReason("");
        setOpen(!open);
      }}
    >
      <div>
        <div>
          <h5 style={{ color: "grey", fontWeight: "400" }}>
            Titular: {patient?.lastName}, {patient?.firstName}. DNI:{" "}
            {patient?.id_number}, Plan: {patient?.financer_plan}
          </h5>
        </div>
        <div style={{ marginTop: "30px" }}>
          <Formik
            onSubmit={(e) => {
              setReason("");
              handleClose(e);
            }}
            initialValues={{
              reason: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.reason || values.reason == "") {
                errors.reason = "Campo requerido";
              }
              return errors;
            }}
          >
            {({ isValid, setFieldValue, errors }) => {
              return (
                <Form>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      minHeight: "95px",
                    }}
                  >
                    <h6 style={{ fontWeight: "500" }}>
                      Motivo de inactivación
                    </h6>
                    <FormControl style={{ width: "36%", margin: "0 10px" }}>
                      <InputLabel id="select-label">
                        Motivo de inactivación
                      </InputLabel>
                      <Select
                        labelId="select-label"
                        name="reason"
                        value={reason}
                        label="Motivo de inactivación"
                        onChange={(e) => {
                          setFieldValue("reason", e.target.value);
                          setReason(e.target.value);
                        }}
                      >
                        <MenuItem value={"INSURANCE_CANCELATION"}>Baja del seguro</MenuItem>
                        <MenuItem value={"UNCHARGEABLE"}>Incobrabilidad</MenuItem>
                        <MenuItem value={"EMISSION_ERROR"}>Error de emisión</MenuItem>
                        { reasons.map((r) => {
                          return <MenuItem key={r.id} value={r.reason}>{r.label}</MenuItem>
                        })}
                        {/* <MenuItem value={"REGRET"}>Arrepentimiento</MenuItem>
                        <MenuItem value={"UNKNOWN"}>
                          Desconocimiento de compra
                        </MenuItem>
                        <MenuItem value={"DISAGREEMENT"}>
                          Disconformidad Venta
                        </MenuItem>
                        <MenuItem value={"EMISSION_ERROR"}>Error de Emisión</MenuItem>
                        <MenuItem value={"NOT_INTERESTED"}>No le interesa</MenuItem>
                        <MenuItem value={"ECONOMIC_PROBLEMS"}>
                          Problemas Económico
                        </MenuItem>
                        <MenuItem value={"HAS_SERVICE"}>
                          Ya posee el servicio
                        </MenuItem> */}
                      </Select>
                      {errors.reason && (
                        <p style={{ color: "red" }}>{errors.reason}</p>
                      )}
                    </FormControl>
                  </div>

                  <Button
                    disabled={!isValid}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#DA2037",
                      borderRadius: "20px",
                      texttransform: "capitalize",
                      width: "30%",
                      float: "right",
                    }}
                  >
                    Guardar
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </BaseModal>
  );
};
