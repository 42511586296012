import { useState } from "react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "../../styles/MuiDataTableStyle";
import { Box, Fade, PaginationItem, Popper, Toolbar } from "@mui/material";
import TablePagination from "@material-ui/core/TablePagination";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import FeedIcon from "@mui/icons-material/Feed";
import { textLabels, downloadOptions } from "../../utils/muiTableOptions";
import { SeePlanDetailsModal } from "../Modals/SeePlanDetailsModal";
import { useEffect } from "react";
import {
  disablePlan,
  editPlan,
  activatePlan,
} from "../../services/plansService";
import { useStateAuthValue } from "../../context/AuthState";
import { useToast } from "../../context/Toast/ToastProvider";
import { showToast, ToastType } from "../../utils/ToastUtil";
import { convertDateFormat } from "../../utils/dateForm";
import { editPayment } from "../../services/PaymentService";
import CustomToolbar from "./CustomTollbar";
import { NewUserTollbar } from "./NewUserTollbar";
import CreatePlan from "../../pages/createPlan";

export const PlanTable = ({
  data,
  getPlansData,
  setLoading,
  totalPages,
  setTotalPages,
  dataLength,
  currentPage,
}) => {
  const [{ userToken }] = useStateAuthValue();
  const toastDispatch = useToast();
  const [finalData, setFinalData] = useState(data);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openCreateNewModal, setOpenCreateNewModal] = useState(false);
  const [openInactivationModal, setOpenInactivationModal] = useState(false);
  const [user, setUser] = useState({});
  const [textSearch, setTextSearch] = useState("");
  const [reason, setReason] = useState("");
  /*   const [filter, setFilter] = useState([
    {
      label: 'Apellido',
      filterBy: '',
      column: 'lastName',
      objective: 'equal',
    },
    {
      label: 'Nombres',
      filterBy: '',
      column: 'firstName',
      objective: 'equal',
    },
    {
      label: 'DNI',
      filterBy: '',
      column: 'id_number',
      objective: 'equal',
    },
    {
      label: 'Correo',
      filterBy: '',
      column: 'email',
      objective: 'equal',
    },
    {
      label: 'Plan',
      filterBy: '',
      column: 'financer_plan',
      objective: 'equal',
    },
  ]);

  const [filterData, setFilterData] = useState({
    lastName: '',
    firstName: '',
    id_number: '',
    financer_plan: '',
    email: '',
  }); */

  useEffect(() => {
    setFinalData(data);
  }, [data]);

  const displayError = (msg) =>
    showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);

  /* const lookForFilter = () => {
    const dataFilters = filter.filter((filter) => filter.filterBy !== '');

    if (dataFilters.length === 0) {
      setFinalData(data);
      return;
    }

    const filteredData = data.filter((item) => {
      for (let key in filterData) {
        if (
          filterData[key] === undefined ||
          !item[key]?.toLowerCase()?.includes(filterData[key]?.toLowerCase())
        ) {
          return false;
        }
      }
      return true;
    });

    setFinalData(filteredData);
  }; */

  const finishEditPlan = async (object) => {
    setOpenDetailsModal(false);
    setLoading(true);
    const newData = {
      ...user,
      ...object,
    };

    editPlan(userToken, newData, displayError).finally(() => {
      setLoading(false);
    });

    setLoading(false);
    setUser({});
    setTimeout(() => {
      getPlansData();
    }, 1000);
  };

  const activateDisabledPlan = async (object) => {
    setLoading(true);

    activatePlan(userToken, object, displayError).finally(() => {
      setLoading(false);
    });

    setTimeout(() => {
      getPlansData();
    }, 1000);
  };

  const columns = [
    {
      name: "planName",
      label: "Nombre de plan",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "consultationsPerMonth",
      label: "Consultas por mes",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "familyMembers",
      label: "Cantidad de miembros",
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: "price",
      label: "Precio",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "priceMember",
      label: "Precio por miembro adicional",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "acciones",
      label: "Acciones",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div className="icons-container">
              <EditIcon
                id={finalData[dataIndex].usrId}
                className="icon edit-icon"
                onClick={() => {
                  setUser(finalData[dataIndex]);
                  setOpenDetailsModal(true);
                }}
              />
              {/* {data[dataIndex]?.activated ? (
                <DoneIcon
                  id={finalData[dataIndex]?.usrId}
                  className="icon done-icon"
                  onClick={() => {
                    setUser(finalData[dataIndex]);
                    setOpenInactivationModal(true);
                  }}
                />
              ) : (
                <DoDisturbAltIcon
                  id={finalData[dataIndex]?.usrId}
                  className="icon cancelled-icon"
                  onClick={() => activateDisabledPlan(finalData[dataIndex])}
                />
              )} */}
              {/*
              <Diversity3Icon
                id={finalData[dataIndex].usrId}
                className="icon people-icon"
                onClick={() => {}}
              />
              <FeedIcon
                id={finalData[dataIndex].usrId}
                className="icon paper-icon"
                onClick={() => {}}
              />
              */}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    searchOpen: true,
    selectableRows: "none",
    textLabels,
    downloadOptions,
    filterType: "dropdown",
    responsive: "standard",
    draggableColumns: { enabled: true },
    enableNestedDataAccess: ".",
    page: currentPage,
    count: totalPages,
    serverSide: true,
    pagination: false,
    elevation: 0,
    onSearchChange: (searchQuery) => {
      getPlansData({ query: searchQuery });
    },
    onTableChange: (action, tableState) => {
      if (action === "search") {
        setTextSearch(tableState.searchText);
      }
    },
    customToolbar: () => {
      return (
        <NewUserTollbar
          onClickFunc={() => {
            setOpenCreateNewModal(true);
          }}
        />
      );
    },
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <Box
        sx={{
          width: "100%",
          "& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-head": {
            span: {
              justifyContent: "center",
            },
          },
        }}
      >
        <MUIDataTable
          title=""
          data={finalData}
          columns={columns}
          options={options}
        />
        <TablePagination
          component="div"
          count={totalPages}
          page={currentPage}
          onPageChange={(state, number) => {
            getPlansData({ query: textSearch, page: number });
          }}
          labelRowsPerPage=""
          rowsPerPage={dataLength}
          rowsPerPageOptions={[dataLength]}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`
          }
        />
        <CreatePlan
          open={openCreateNewModal}
          setOpen={setOpenCreateNewModal}
          getPlansData={() => {
            setLoading(true);
            setTimeout(() => {
              getPlansData();
              setLoading(false);
            }, 2000);
          }}
        />
        <SeePlanDetailsModal
          open={openDetailsModal}
          setOpen={setOpenDetailsModal}
          handleClose={finishEditPlan}
          plan={user}
        />
      </Box>
    </ThemeProvider>
  );
};
