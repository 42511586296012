import { useState } from "react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "../../styles/MuiDataTableStyle";
import { Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { textLabels, downloadOptions } from "../../utils/muiTableOptions";
import { SeePatientDetailsModal } from "../Modals/SeePatientDetailsModal";
import { useEffect } from "react";
import CustomToolbar from "./CustomTollbar";

export const MySalesTable = ({ data, getPatientsData, setLoading }) => {
  const [finalData, setFinalData] = useState(data);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [user, setUser] = useState({});
  const [filter, setFilter] = useState([
    {
      label: 'Fecha desde',
      filterBy: '',
      column: 'lastName',
      objective: 'min',
    },
    {
      label: 'Fecha hasta',
      filterBy: '',
      column: 'firstName',
      objective: 'max',
    },
    {
      label: 'Links',
      filterBy: [''],
      column: 'financer_plan',
      objective: 'select',
      options: [
        { label: "Enviados", value: false, checked:false },
        { label: "Pagados", value: true, checked:false },
      ],
    },
  ]);

  const [filterData, setFilterData] = useState({
    lastName: '',
    firstName: '',
    id_number: '',
    financer_plan: '',
    email: '',
  });

  useEffect(() => {
    setFinalData(data)
  },[data]);


  const lookForFilter = () => {
/*     const dataFilters = filter.filter((filter) => filter.filterBy !== '');

    if (dataFilters.length === 0) {
      setFinalData(data);
      return;
    }

    const filteredData = data.filter((item) => {
      for (let key in filterData) {
        if (
          filterData[key] === undefined ||
          !item[key]?.toLowerCase()?.includes(filterData[key]?.toLowerCase())
        ) {
          return false;
        }
      }
      return true;
    });

    setFinalData(filteredData); */
  };

  const editUser = () => {
    setOpenPaymentModal(false);
    setUser({});
  };
  const columns = [
    {
      name: 'lastName',
      label: 'Apellido',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'firstName',
      label: 'Nombre',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'id_number',
      label: 'DNI',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'email',
      label: 'Correo',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'phone',
      label: 'Celular',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'financer_plan',
      label: 'Fch. Envio Link',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'financer_plan',
      label: 'Fch. Pago Link',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'acciones',
      label: 'Acciones',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div className="icons-container" style={{ display: "flex"}}>
              <EditIcon
                id={finalData[dataIndex].usrId}
                className="icon edit-icon"
                onClick={() => {
                  setUser(finalData[dataIndex]);
                  setOpenDetailsModal(true);
                }}
              />
              <WhatsAppIcon
                id={finalData[dataIndex].usrId}
                className="icon whatsapp-icon"
                onClick={() => {
                }}
              />
            </div>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: 'none',
    textLabels,
    downloadOptions,
    filterType: 'dropdown',
    responsive: 'standard',
    draggableColumns: { enabled: true },
    enableNestedDataAccess: '.',
    elevation: 0,
    customToolbar: () => {
      return (
        <CustomToolbar
          filter={filter}
          setFilter={setFilter}
          setFilterData={setFilterData}
          lookForFilter={lookForFilter}
        />
      );
    },
    filter: false,
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <Box
        sx={{
          width: '100%',
          '& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-head': {
            span: {
              justifyContent: 'center',
            },
          },
        }}
      >
        <MUIDataTable
          title=""
          data={finalData}
          columns={columns}
          options={options}
        />
        <SeePatientDetailsModal
          open={openDetailsModal}
          setOpen={setOpenDetailsModal}
          handleClose={editUser}
          patient={user}
        />
      </Box>
    </ThemeProvider>
  );
};
