import axios from "axios";

const financerPlansUrl = `${process.env.REACT_APP_BASE_URL}/services/parametrics/api/financer-plans/backoffice`;
const financerPlansBaseUrl = `${process.env.REACT_APP_BASE_URL}/services/parametrics/api/financer-plans`;

export const getFinancerPlans = async (token, displayError = () => {}, params) => {
  let url = `${financerPlansUrl}${params?.query || params?.page ? "?" : ""}${
    params?.query && params?.query !== "" ? `search=` + params?.query : ``
  }${params?.query && params?.page ? `&` : ""}${
    params?.page ? `page=` + params?.page : ``
  }`;
  return await axios
    .get(url, { headers: { authorization: `Bearer ${token}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("getFinancerPlans error: " + error.message);
    });
};

export const editFinancerPlan = async (token, data, displayError = () => {}) => {
  return await axios
    .put(`${financerPlansBaseUrl}/${data?.id}`, data, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("editFinancerPlan error: " + error.message);
    });
};

export const activateFinancerPlan = async (token, data, displayError = () => {}) => {
  return await axios
    .post(
      `${financerPlansUrl}/${data?.id}`,
      { ...data, activated: true },
      { headers: { authorization: `Bearer ${token}` } }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("activateFinancerPlan error: " + error.message);
    });
};

export const disableFinancerPlan = async (
  token,
  id,
  reason,
  displayError = () => {}
) => {
  return await axios
    .delete(`${financerPlansUrl}/${id}/${reason}`, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("disableFinancerPlan error: " + error.message);
    });
};

export const createFinancerPlan = async (token, data, displayError = () => {}) => {
  return await axios
    .post(`${financerPlansBaseUrl}`, data, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("createFinancerPlan error: " + error.message);
    });
};

export const getFinancerPlanById = async (token, id, displayError = () => {}) => {
  return await axios
    .get(`${financerPlansBaseUrl}/financer/${id}`, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("getFinancerPlanByFinancerId error: " + error.message);
    });
};

export const deleteFinancerPlan = async (token, id, displayError = () => {}) => {
  return await axios
    .delete(`${financerPlansBaseUrl}/${id}`, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("deleteFinancerPlan error: " + error.message);
    });
};