import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { createFinancerPlan } from "../services/financerPlansService";
import { getAllAgreements } from "../services/agreementsService";
import { getAllPlans } from "../services/plansService";

import { useStateAuthValue } from "../context/AuthState";
import { useToast } from "../context/Toast/ToastProvider";
import { showToast, ToastType } from "../utils/ToastUtil";
import { Form, Formik } from "formik";
import { FormikField, SelectObjectField } from "../components/FormFields";
import * as Yup from "yup";

import { BaseModal } from "../components/Modals/BaseModal";

const CreateFinancerPlan = ({
  open,
  setOpen,
  getFinancerPlansData,
  financerPlanData,
}) => {
  const [{ userToken }] = useStateAuthValue();
  const toastDispatch = useToast();
  const displayError = (msg) =>
    showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);
  const [financerPlan, setFinancerPlan] = useState({
    planId: null,
    planName: "",
    consultationsPerMonth: "",
    familyMembers: "",
    price: "",
    priceMember: "",
    financerId: financerPlanData ? financerPlanData.financerId : null,
    agreementId: financerPlanData ? financerPlanData.agreementId : null,
    financer: financerPlanData
      ? financerPlanData.financer
      : { financerName: "", id: null },
    agreement: financerPlanData
      ? financerPlanData.agreement
      : { description: "", id: null },
  });

  const FormScheme = Yup.object().shape({
    //consultationsPerMonth: Yup.string().required("No debe quedar vacío"),
    //familyMembers: Yup.string().required("No debe quedar vacío"),
    //price: Yup.string().required("No debe quedar vacío"),
    //priceMember: Yup.string().required("No debe quedar vacío"),
    financerId: Yup.string().required("No debe quedar vacío"),
    agreementId: Yup.string().required("No debe quedar vacío"),
    planId: Yup.string().required("No debe quedar vacío"),
  });

  const [agreementsList, setAgreementsList] = useState([]);
  const [planList, setPlanList] = useState([]);
  const [allAgreements, setAllAgreements] = useState([]);
  const [allPlans, setAllPlans] = useState([]);

  const showError = (msgError) => {
    showToast(toastDispatch, msgError, ToastType.ERROR, ToastType.ERROR);
  };
  const formikSetValues = useRef(null);

  const fetchAgreements = async () => {
    const response = await getAllAgreements(userToken, showError);

    let _agreementList = [];
    response?.map((agreement) => {
      _agreementList = [
        ..._agreementList,
        {
          name: agreement.financer.financerName + " " + agreement.description,
          id: agreement.id,
        },
      ];
    });
    setAllAgreements(response);
    setAgreementsList(_agreementList);
  };

  const fetchPlans = async () => {
    const response = await getAllPlans(userToken, showError);

    let _planList = [];
    response?.map((plan) => {
      _planList = [
        ..._planList,
        {
          name: plan.planName,
          id: plan.id,
        },
      ];
    });

    setAllPlans(response);
    setPlanList(_planList);
  };

  useEffect(() => {
    fetchAgreements();
    fetchPlans();
  }, []);

  const handleCreateFinancerPlan = (values) => {
    // values.agreementId = currentAgreement.id;
    // values.financerId = currentFinancer.id;
    createFinancerPlan(userToken, values, displayError).then((e) => {
      if (e != null) {
        showToast(
          toastDispatch,
          "Se creo con exito",
          ToastType.SUCCESS,
          ToastType.SUCCESS
        );
      }
      getFinancerPlansData();
    });
  };

  return (
    <BaseModal open={open} setOpen={setOpen}>
      <h3>Asociar plan al convenio</h3>
      <Formik
        onSubmit={(values) => {
          handleCreateFinancerPlan(values);
          setOpen(false);
        }}
        initialValues={financerPlan}
        validationSchema={FormScheme}
      >
        {({
          isValid,
          values,
          setValues,
          setFieldValue,
          errors,
          handleSubmit,
        }) => {
          formikSetValues.current = setValues;
          return (
            <Form>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2,1fr)",
                  gridGap: "25px",
                }}
              >
                <SelectObjectField
                  label="Empresa - Convenio"
                  setValue={(e) => {
                    let _agreement = allAgreements?.filter(a => a.id===e.id);
                    setValues({ ...values, agreementId: e.id, financerId: _agreement!=null && _agreement.length>0 ? _agreement[0].financerId : null});

                    financerPlan.financer.financerName=_agreement[0].financer.financerName;
                    financerPlan.agreement.description=_agreement[0].description;
                  }}
                  value={{
                    name: financerPlan?.financer?.financerName + ' ' + financerPlan?.agreement?.description,
                    id: financerPlan?.agreementId
                  }}
                  data={agreementsList}
                  className="field"
                  errorMsg={errors.agreementId}
                  isError={errors.agreementId}
                  disabled={true}
                />
                <SelectObjectField
                  label="Plan"
                  setValue={(e) => {
                    let _plan = allPlans?.filter((p) => p.id === e.id);
                    _plan = _plan.length > 0 ? _plan[0] : [];
                    setValues({
                      ...values,
                      planId: e.id,
                      planName: e.name,
                      price: _plan.price,
                      priceMember: _plan.priceMember,
                      familyMembers: _plan.familyMembers,
                      consultationsPerMonth: _plan.consultationsPerMonth,
                    });
                    financerPlan.planName = e.name;
                  }}
                  value={{
                    name: financerPlan?.planName,
                    id: financerPlan?.planId,
                  }}
                  data={planList}
                  className="field"
                  errorMsg={errors.planId}
                  isError={errors.planId}
                />
                <FormikField
                  name="consultationsPerMonth"
                  label="Consultas por mes"
                  disabled={true}
                />
                <FormikField
                  name="familyMembers"
                  label="Cantidad de miembros"
                  disabled={true}
                />
                <FormikField name="price" label="Precio" disabled={true} />
                <FormikField
                  name="priceMember"
                  disabled={true}
                  label="Precio por miembro adicional"
                />
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  disabled={values.planId == null}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#002c54",
                    margin: "30px 10px 0 0",
                    borderRadius: "20px",
                    texttransform: "capitalize",
                  }}
                  onClick={handleSubmit}
                >
                  Guardar
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </BaseModal>
  );
};

export default CreateFinancerPlan;
