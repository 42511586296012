import axios from "axios";

const agreementsUrl = `${process.env.REACT_APP_BASE_URL}/services/parametrics/api/agreements/backoffice`;
const agreementsBaseUrl = `${process.env.REACT_APP_BASE_URL}/services/parametrics/api/agreements`;

export const getAgreements = async (token, displayError = () => {}, params) => {
  let url = `${agreementsUrl}${params?.query || params?.page ? "?" : ""}${
    params?.query && params?.query !== "" ? `search=` + params?.query : ``
  }${params?.query && params?.page ? `&` : ""}${
    params?.page ? `page=` + params?.page : ``
  }`;
  return await axios
    .get(url, { headers: { authorization: `Bearer ${token}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("getAgreements error: " + error.message);
    });
};

export const editAgreement = async (token, data, displayError = () => {}) => {
  return await axios
    .put(`${agreementsBaseUrl}/${data?.id}`, data, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("editAgreement error: " + error.message);
    });
};

export const activateAgreement = async (token, data, displayError = () => {}) => {
  return await axios
    .post(
      `${agreementsUrl}/${data?.id}`,
      { ...data, activated: true },
      { headers: { authorization: `Bearer ${token}` } }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("activateAgreement error: " + error.message);
    });
};

export const disableAgreement = async (
  token,
  id,
  reason,
  displayError = () => {}
) => {
  return await axios
    .delete(`${agreementsUrl}/${id}/${reason}`, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("disableAgreement error: " + error.message);
    });
};

export const createAgreement = async (token, data, displayError = () => {}) => {
  return await axios
    .post(`${agreementsBaseUrl}`, data, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("createAgreement error: " + error.message);
    });
};

export const getAllAgreements = async (token, displayError = () => {}, params) => {
  let url = `${agreementsBaseUrl}`;
  return await axios
    .get(url, { headers: { authorization: `Bearer ${token}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("getAllAgreements error: " + error.message);
    });
};
export const getLastAgreementNumber = async (token, displayError = () => {}, params) => {
  let url = `${agreementsBaseUrl}/last-agreement-number`;
  return await axios
    .get(url, { headers: { authorization: `Bearer ${token}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("getAllAgreements error: " + error.message);
    });
};