const patientInitialValues = {
   alias: '',
   first_name: '',
   last_name: '',
   id_number: '',
   phone_number: '',
   email:"",
};

export default patientInitialValues;
