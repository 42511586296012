import { useState } from "react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "../../styles/MuiDataTableStyle";
import {
  Box,
} from "@mui/material";
import TablePagination from "@material-ui/core/TablePagination";
import { textLabels, downloadOptions } from "../../utils/muiTableOptions";
import { useEffect } from "react";
import { convertDateFormat } from "../../utils/dateForm";
import { ToastType, checkStatus, showToast } from "../../utils/ToastUtil";
import { useToast } from "../../context/Toast/ToastProvider";
import { useStateAuthValue } from "../../context/AuthState";
import { RestartAlt } from "@mui/icons-material";
import { resetNotification } from "../../services/notificationsService";
import Tooltip from "@mui/material/Tooltip";
import { Error } from "@mui/icons-material";

export const NotificationTable = ({
  data,
  getNotificationsData,
  setLoading,
  totalPages,
  setTotalPages,
  dataLength,
  currentPage,
  event
}) => {
  const [finalData, setFinalData] = useState(data);
  const [textSearch, setTextSearch] = useState(null);
  const [{ userToken }] = useStateAuthValue();
  const toastDispatch = useToast();

  const displayError = (msg) =>
    showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);

  useEffect(() => {
    setFinalData(data);
  }, [data]);

  const handleResetNotification = async (params) => {
    setLoading(true);
    resetNotification(userToken, displayError, params).finally(() => {
      setLoading(false);
    }).finally(() => {
      let params = {}
      params.status = 'ERROR'
      params.meanTypes = ['WHATSAPP', 'EMAIL']
      params.event = event
      getNotificationsData(params);
    });
  };

  const columns = [
    {
      name: "notification.event",
      label: "Nombre",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return <p>{checkStatus(finalData[dataIndex].notification.event)}</p>;
        },
      },
    },
    {
      name: "creationDate",
      label: "Fecha de creación",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "retries",
      label: "Reintentos",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Estado",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "type",
      label: "Tipo",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "receipt",
      label: "Recepción",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "notification.senderLogin",
      label: "Correo",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "acciones",
      label: "Acciones",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div>
              <Tooltip title={finalData[dataIndex]?.statusReason} arrow>
                <Error/>
              </Tooltip>
              <RestartAlt
                id={finalData[dataIndex]?.usrId}
                className="icon cancelled-icon"
                onClick={() => handleResetNotification(finalData[dataIndex])}
              />
            </div>
          )
        }
      },
    },
  ];

  const options = {
    searchOpen: true,
    selectableRows: "none",
    textLabels,
    downloadOptions,
    filterType: "dropdown",
    responsive: "standard",
    draggableColumns: { enabled: true },
    enableNestedDataAccess: ".",
    page: currentPage,
    count: totalPages,
    serverSide: true,
    pagination: false,
    elevation: 0,
    onSearchChange: (searchQuery) => {
      getNotificationsData({
        search: searchQuery,
        status: 'ERROR',
        meanTypes: ['WHATSAPP', 'EMAIL'],
        event: 'CONSULTATION_FINISHED',
      });
    },
    onTableChange: (action, tableState) => {
      if (action === "search") {
        setTextSearch(tableState.searchText);
      }
    },
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <Box
        sx={{
          width: "100%",
          "& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-head": {
            span: {
              justifyContent: "center",
            },
          },
        }}
      >
        <MUIDataTable
          title=""
          data={finalData}
          columns={columns}
          options={options}
        />

        <TablePagination
          component="div"
          count={totalPages}
          page={currentPage}
          onPageChange={(state, number) => {
            getNotificationsData({
              search: textSearch,
              page: number,
              status: 'ERROR',
              meanTypes: ['WHATSAPP', 'EMAIL'],
              event: 'CONSULTATION_FINISHED',
            });
          }}
          labelRowsPerPage=""
          rowsPerPage={dataLength}
          rowsPerPageOptions={[dataLength]}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`
          }
        />
      </Box>
    </ThemeProvider>
  );
};
