export const getFormattedCurrentDate = (subtractMonth, dayDefault) => {
    const currentDate = new Date();
    const currentDateUpdate = new Date(currentDate);
    currentDateUpdate.setMonth(currentDate.getMonth() - subtractMonth);
  
    const year = currentDateUpdate.getFullYear();
    const month = String(currentDateUpdate.getMonth() + 1).padStart(2, '0');
    let day = String(currentDateUpdate.getDate()).padStart(2, '0');
    if (dayDefault) day = dayDefault
    const formattedDate = `${year}-${month}-${day}`;
  
    return formattedDate;
}