import { useEffect, useState } from "react";
import { useStateAuthValue } from "../context/AuthState";
import Login from "../pages/login";
import SidebarLayout from "../components/Layout";

const ProtectedRoute = (props) => {
  const [{ userToken }, dispatch] = useStateAuthValue();
  const [isUserLoggedIn, setUserIsLoggedIn] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (userToken || token) {
        setUserIsLoggedIn(true);
        if (!userToken){
          dispatch({
            type: "LOG_IN",
            userToken: token,
          });
        }
    } else {
      setUserIsLoggedIn(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, userToken]);

  if (isUserLoggedIn) {
    return <SidebarLayout>{props.children}</SidebarLayout>;
  } else {
    return <Login />;
  }
};
export default ProtectedRoute;
