
const translations = {
  uploadStep: {
      title: "Subir archivo",
      manifestTitle: "Información esperada:",
      manifestDescription: "(Tendrás la posibilidad de renombrar o eliminar columnas en el siguiente paso)",
      maxRecordsExceeded: (maxRecords) => `Muchos registros. Hasta ${maxRecords} permitidos`,
      dropzone: {
          title: "Subir archivos.xlsx, .xls or .csv",
          errorToastDescription: "Archivo rechazado",
          activeDropzoneTitle: "Arrastre el archivo aquí...",
          buttonTitle: "Seleccione el archivo",
          loadingTitle: "Procesando...",
      },
      selectSheet: {
          title: "Seleccione la hoja a utilizar",
          nextButtonTitle: "Siguiente",
          backButtonTitle: "Volver",
      },
  },
  selectHeaderStep: {
      title: "Seleccionar nombres de columnas",
      nextButtonTitle: "Siguiente",
      backButtonTitle: "Volver",
  },
  matchColumnsStep: {
      title: "Columnas coincidentes",
      nextButtonTitle: "Siguiente",
      backButtonTitle: "Volver",
      userTableTitle: "Su tabla",
      templateTitle: "Se importará como:",
      selectPlaceholder: "Seleccione columna...",
      ignoredColumnText: "Columna ignorada",
      subSelectPlaceholder: "Seleccione...",
      matchDropdownTitle: "Coincide",
      unmatched: "No coincide",
      duplicateColumnWarningTitle: "Otra columna no seleccionada",
      duplicateColumnWarningDescription: "Columnas no deben duplicarse",
  },
  validationStep: {
      title: "Validar información",
      nextButtonTitle: "Confirmar",
      backButtonTitle: "Volver",
      noRowsMessage: "No se encontró información",
      noRowsMessageWhenFiltered: "No hay errores",
      discardButtonTitle: "Ignorar filas seleccionadas",
      filterSwitchTitle: "Visualizar solo filas con errores",
  },
  alerts: {
      confirmClose: {
          headerTitle: "Salir de la importación",
          bodyText: "Si sale la información no será procesada",
          cancelButtonTitle: "Volver a importación",
          exitButtonTitle: "Salir y no grabar",
      },
      submitIncomplete: {
          headerTitle: "Errores detectados",
          bodyText: "Algunas filas todavía contienen errores. Filas con errores serán ignoradas cuando se proceda a grabar.",
          bodyTextSubmitForbidden: "Algunas filas todavía contienen errores.",
          cancelButtonTitle: "Cancelar",
          finishButtonTitle: "Grabar",
      },
      submitError: {
          title: "Error",
          defaultMessage: "Ocurrio un error mientras se grababa la información",
      },
      unmatchedRequiredFields: {
          headerTitle: "No todas las columnas coinciden",
          bodyText: "Existen columnas obligatorias que no coinciden o se ignoraron. ¿Desea continuar?",
          listTitle: "Columnas no coincidentes:",
          cancelButtonTitle: "Cancelar",
          continueButtonTitle: "Continuar",
      },
      toast: {
          error: "Error",
      },
  },
};

export { translations };
