import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Formik, Form } from "formik";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import * as Yup from "yup";
import {FormikField} from "../FormFields";
import { BaseModal } from "./BaseModal";

export const PaymentDetailsModals = ({
  open,
  setOpen,
  handleClose,
  patient,
}) => {
  const [paymentForm, setPaymentForm] = useState("");
  const [isCbu, setIsCbu] = useState(false);
  const [isCard, setIsCard] = useState(false);
  const validateCard = {
    card_number: Yup.string().required("No debe quedar vacío"),
    card_date_expiration: Yup.string().required("No debe quedar vacío"),
    card_owner: Yup.string().required("No debe quedar vacío"),
  }
  const validateCbu = {
    cbu_number: Yup.string().required("No debe quedar vacío"),
    cbu_owner: Yup.string().required("No debe quedar vacío"),
  }

  useEffect(() => {
    if (patient?.cbu){
      setPaymentForm('CBU');
      setIsCbu(true);
      setIsCard(false);
    }
    if (patient.tarjeta_numero){
      setPaymentForm('Tarjeta de Crédito o Debito');
      setIsCard(true);
      setIsCbu(false);
    }
  }, [patient]);

  const FormScheme = Yup.object().shape(isCard ? validateCard : validateCbu);

  const replaceLastSixLetters = (str) => {
    const pattern = /^[A-Za-z0-9]{4} [A-Za-z0-9]{4} [A-Za-z0-9]{4} [A-Za-z0-9]{4}$/;

    if(pattern.test(str)){
        const firstQuartet = str.slice(0, 7);
        const secondQuartet = "**";
        const thirdQuartet = "****";
        const lastFourDigits = str.slice(-4);
      
        return `${firstQuartet} ${secondQuartet} ${thirdQuartet} ${lastFourDigits}`;
    }
    return str;
  }

  const handlePaymentForm = (event) =>{
    event.preventDefault();
    setPaymentForm(event.target.value);
    if (event.target.value === 'CBU'){
      setIsCbu(true);
      setIsCard(false);
    }else{
      setIsCard(true);
      setIsCbu(false);
    }
  }

  const handleCloseCustom = (value) =>{
    if(isCbu){
      value.card_date_expiration = '';
      value.card_number = '';
      value.card_owner = '';
    }else{
      value.cbu_number = '';
      value.cbu_owner = '';
    }
    handleClose(value);
  }

  const formTypePayment = (
    <FormControl style={{margin: "0 10px" }}>
      <InputLabel id="select-label">Forma de pago</InputLabel>
        <Select
          labelId="select-label"
          id="simple-select"
          value={paymentForm}
          label="Forma de pago"
          onChange={handlePaymentForm}
        >
          <MenuItem value={"Tarjeta de Crédito o Debito"}>Tarjeta de Crédito o Debito</MenuItem>
          <MenuItem value={"CBU"}>CBU</MenuItem>
        </Select>
    </FormControl>
  )

  return (
    <BaseModal width="650px" open={open} setOpen={setOpen}>
      <div>
        <div>
          <h5 style={{ color: "grey", fontWeight: "400" }}>
            Titular: {patient?.lastName}, {patient?.firstName}. DNI:{" "}
            {patient?.id_number}, Plan: {patient?.financer_plan || "No especificado"}
          </h5>
        </div>
        <div style={{ marginTop: "30px"}}>
        <Formik
          initialValues={{
            card_number: patient?.tarjeta_numero ? patient?.tarjeta_numero : "",
            card_date_expiration: patient?.tarjeta_fecha_vencimiento ? patient?.tarjeta_fecha_vencimiento : "",
            card_owner: patient?.tarjeta_titular ? patient?.tarjeta_titular : "",
            cbu_number: patient?.cbu ? patient?.cbu : "",
            cbu_owner: patient?.cbu_titular ? patient?.cbu_titular: "",
          }}
          validationSchema={FormScheme}
        >
          {({ values, isValid }) => {
            return (
              <Form>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {isCard && (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2,1fr)",
                        gridGap: "25px",
                      }}
                    >
                      {formTypePayment}
                      <FormikField name="card_number" label="N° de tarjeta" />
                      <FormikField name="card_date_expiration" label="Fecha de vencimiento" />
                      <FormikField name="card_owner" label="Titular de tarjeta" />
                    </div>
                  )}
                  {isCbu && (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2,1fr)",
                        gridGap: "25px",
                      }}
                    >
                      {formTypePayment}
                      <FormikField name="cbu_number" label="N° de Cbu" />
                      <FormikField name="cbu_owner" label="Titular del Cbu" />
                    </div>
                  )}
                </div>
                <div style={{ display: "flex"}}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#DA2037",
                    margin: "30px 10px 0 0",
                    borderRadius: "20px",
                    texttransform: "capitalize",
                  }}
                  onClick={() => setOpen(false)}
                >
                  Cancelar
                </Button>
                <Button
                  disabled={!isValid}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#002c54",
                    margin: "30px 10px 0 0",
                    borderRadius: "20px",
                    texttransform: "capitalize",
                  }}
                  onClick={() => handleCloseCustom(values)}
                >
                  Guardar
                </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
        </div>
      </div>
    </BaseModal>
  );
};
