import { useState } from "react";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import { ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "../../styles/MuiDataTableStyle";
import {
  Box,
  Button,
  Fade,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  PaginationItem,
  Popper,
  TextField,
  Toolbar,
  Tooltip,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import PaymentIcon from "@mui/icons-material/Payment";
import TablePagination from "@material-ui/core/TablePagination";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import FeedIcon from "@mui/icons-material/Feed";
import { textLabels, downloadOptions } from "../../utils/muiTableOptions";
import { SeePatientDetailsModal } from "../Modals/SeePatientDetailsModal";
import { PaymentDetailsModals } from "../Modals/PaymentDetailsModal";
import { InactivatePatientModal } from "../Modals/InactivatePatientModal";
import { useEffect } from "react";
import {
  disablePatient,
  editPatient,
  activatePatient,
} from "../../services/patientsService";
import { useStateAuthValue } from "../../context/AuthState";
import { useToast } from "../../context/Toast/ToastProvider";
import { showToast, ToastType } from "../../utils/ToastUtil";
import { convertDateFormat } from "../../utils/dateForm";
import { editPayment } from "../../services/PaymentService";
import CreatePatient from "../../pages/createPatient";
import { Select } from "@mui/material";
import { Checkbox } from "@mui/material";
import { ListItemText } from "@mui/material";
import { onSpaceOrEnter } from "@mui/x-date-pickers/internals";
import { ClearIcon } from "@mui/x-date-pickers";
import { Search } from "@mui/icons-material";

export const ClientTable = ({
  data,
  getPatientsData,
  setLoading,
  totalPages,
  setTotalPages,
  dataLength,
  currentPage,
}) => {
  const [{ userToken }] = useStateAuthValue();
  const toastDispatch = useToast();
  const [finalData, setFinalData] = useState(data);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openCreateNewModal, setOpenCreateNewModal] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openInactivationModal, setOpenInactivationModal] = useState(false);
  const [user, setUser] = useState({});
  const [textSearch, setTextSearch] = useState(null);
  const [activated, setActivatedFilter] = useState(null);
  const [financerPlan, setFinancerPlan] = useState(null);
  const [financer, setFinancer] = useState(null);
  const [reason, setReason] = useState("");
  const [lastTextSearch, setLastTextSearch] = useState("");

  useEffect(() => {
    setFinalData(data);
  }, [data]);

  const displayError = (msg) =>
    showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);

  const finishEditPatient = async (object) => {
    setOpenDetailsModal(false);
    setLoading(true);
    const newData = {
      ...user,
      ...object,
    };

    editPatient(userToken, newData, displayError).finally((e) => {
      showToast(
        toastDispatch,
        "Se creo con exito",
        ToastType.SUCCESS,
        ToastType.SUCCESS
      );

      setLoading(false);
    });

    setLoading(false);
    setUser({});
    setTimeout(() => {
      getPatientsData({
        search: textSearch.trim(),
        activated,
        financerPlan,
        financer,
      });
    }, 1000);
  };

  const finishEditPayment = async (object) => {
    setOpenPaymentModal(false);
    setLoading(true);

    editPayment(userToken, user.id, object, displayError).finally(() => {
      setLoading(false);
    });

    setUser({});
    setTimeout(() => {
      getPatientsData({
        search: textSearch.trim(),
        activated,
        financerPlan,
        financer,
      });
    }, 1000);
  };

  const activateDisabledPatient = async (object) => {
    setLoading(true);

    activatePatient(userToken, object, displayError).finally(() => {
      setLoading(false);
    });

    setTimeout(() => {
      getPatientsData({
        search: textSearch.trim(),
        activated,
        financerPlan,
        financer,
      });
    }, 1000);
  };

  const inactivatePatient = async () => {
    setOpenInactivationModal(false);
    setLoading(true);

    disablePatient(userToken, user?.id, reason, displayError).finally(() => {
      setLoading(false);
    });
    setTimeout(() => {
      getPatientsData({
        search: textSearch.trim(),
        activated,
        financerPlan,
        financer,
      });
    }, 1000);
  };

  const columns = [
    {
      name: "created_data",
      label: "Fecha de alta",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return <p>{convertDateFormat(finalData[dataIndex].created_data)}</p>;
        },
      },
    },
    {
      name: "lastName",
      label: "Apellido",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "firstName",
      label: "Nombres",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "id_number",
      label: "DNI",
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: "phone",
      label: "Celular",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Correo",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "financer_plan",
      label: "Plan",
      options: {
        filter: true,
        sort: true,
        filterType: "custom",
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            const optionValues = [
              "CLASSIC",
              "CLASSIC BNF",
              "PLUS",
              "PLUS BNF",
              "PREMIUM",
              "PREMIUM BNF",
              "ASISTENCIA MEDICA",
            ];
            return (
              <FormControl style={{ width: "10rem" }}>
                <InputLabel htmlFor="select-multiple-chip">Plan</InputLabel>
                <Select
                  value={filterList[index]}
                  onChange={(event) => {
                    onChange(
                      event.target.value === "" ? [] : [event.target.value],
                      index,
                      column
                    );
                  }}
                >
                  {optionValues.map((item) => (
                    <MenuItem key={item} value={item}>
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: "financer",
      label: "Financiador",
      options: {
        filter: true,
        sort: true,
        filterType: "custom",
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            const optionValues = financersNameList;
            return (
              <FormControl style={{ width: "10rem" }}>
                <InputLabel htmlFor="select-multiple-chip">Empresa</InputLabel>
                <Select
                  value={filterList[index]}
                  onChange={(event) => {
                    onChange(
                      event.target.value === "" ? [] : [event.target.value],
                      index,
                      column
                    );
                  }}
                >
                  {optionValues.map((item) => (
                    <MenuItem key={item} value={item}>
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          },
        },
      },
    },
    {
      name: "agreement_id",
      label: "Nro. Convenio",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "acciones",
      label: "Acciones",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div className="icons-container">
              <EditIcon
                id={finalData[dataIndex].usrId}
                className="icon edit-icon"
                onClick={() => {
                  setUser(finalData[dataIndex]);
                  setOpenDetailsModal(true);
                }}
              />
              {(finalData[dataIndex].cbu ||
                finalData[dataIndex].tarjeta_numero) && (
                <PaymentIcon
                  id={finalData[dataIndex].usrId}
                  className="icon"
                  onClick={() => {
                    setUser(finalData[dataIndex]);
                    setOpenPaymentModal(true);
                  }}
                />
              )}
              {data[dataIndex]?.activated ? (
                <DoneIcon
                  id={finalData[dataIndex]?.usrId}
                  className="icon done-icon"
                  onClick={() => {
                    setUser(finalData[dataIndex]);
                    setOpenInactivationModal(true);
                  }}
                />
              ) : (
                <DoDisturbAltIcon
                  id={finalData[dataIndex]?.usrId}
                  className="icon cancelled-icon"
                  onClick={() => activateDisabledPatient(finalData[dataIndex])}
                />
              )}
              {/*
              <Diversity3Icon
                id={finalData[dataIndex].usrId}
                className="icon people-icon"
                onClick={() => {}}
              />
              <FeedIcon
                id={finalData[dataIndex].usrId}
                className="icon paper-icon"
                onClick={() => {}}
              />
              */}
            </div>
          );
        },
        filterType: "custom",
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            const optionValues = ["ACTIVO", "INACTIVO"];
            return (
              <FormControl style={{ width: "10rem" }}>
                <InputLabel htmlFor="select-multiple-chip">Estado</InputLabel>
                <Select
                  value={filterList[index]}
                  onChange={(event) => {
                    onChange(
                      event.target.value === "" ? [] : [event.target.value],
                      index,
                      column
                    );
                  }}
                >
                  {optionValues.map((item) => (
                    <MenuItem key={item} value={item}>
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          },
        },
      },
    },
  ];
  const handleSearchKeyDown = (event) => {
    if (event.key === "Enter") {
      // Aquí defines lo que quieres que pase al presionar "Enter"
      //event.target.blur();
      setLastTextSearch(textSearch?.trim());
      getPatientsData({
        search: textSearch.trim(),
        activated,
        financerPlan,
        financer,
      });
    }
  };
  const customSearchRender = (
    searchText,
    handleSearch,
    hideSearch,
    options
  ) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton
          onClick={() => {
            getPatientsData({
              search: textSearch,
              activated,
              financerPlan,
              financer,
            });
            setLastTextSearch(textSearch?.trim());
          }}
        >
          <Search></Search>
        </IconButton>
        <TextField
          variant="standard"
          value={searchText}
          onChange={(event) => handleSearch(event.target.value)}
          onKeyDown={handleSearchKeyDown}
          placeholder="Search..."
          InputProps={{
            "aria-label": "Search",
          }}
        />
        <IconButton
          onClick={() => {
            handleSearch("");
            getPatientsData({
              search: "",
              activated,
              financerPlan,
              financer,
            });
            setLastTextSearch("");
          }}
        >
          <ClearIcon />
        </IconButton>
        {!(!lastTextSearch || lastTextSearch.trim() === "") && (
          <Box
            sx={{
              background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
              color: "white",
              fontWeight: "bold",
              padding: "10px",
              borderRadius: "15px",
            }}
          >
            {lastTextSearch}
          </Box>
        )}
      </div>
    );
  };
  const options = {
    searchOpen: true,
    selectableRows: "none",
    textLabels,
    downloadOptions,
    filterType: "dropdown",
    responsive: "standard",
    draggableColumns: { enabled: true },
    enableNestedDataAccess: ".",
    page: currentPage,
    count: totalPages,
    serverSide: true,
    pagination: false,
    elevation: 0,
    customSearchRender,
    onSearchChange: (searchQuery) => {
      if (searchQuery == null) {
         setTextSearch("");
      }
    },

    onTableChange: (action, tableState) => {
      if (action === "search") {
        setTextSearch(tableState.searchText);
      } else if (action === "filterChange" || action === "resetFilters") {
        let _plan = null;
        let _financer = null;
        let _activated = null;
        //console.log(tableState.filterList);
        //Plan
        if (tableState?.filterList[6].length > 0) {
          setFinancerPlan(tableState.filterList[6][0]);
          _plan = tableState.filterList[6][0];
        } else {
          setFinancerPlan(null);
        }
        //Finanacer
        if (tableState?.filterList[7].length > 0) {
          setFinancer(tableState.filterList[7][0]);
          _financer = tableState.filterList[7][0];
        } else {
          setFinancer(null);
        }
        let filterListLenght;
        if (tableState?.filterList) {
          filterListLenght = tableState.filterList.length;
        }
        if (
          filterListLenght &&
          tableState?.filterList[filterListLenght - 1].length > 0
        ) {
          setActivatedFilter(tableState.filterList[filterListLenght - 1][0]);
          _activated = tableState.filterList[filterListLenght - 1][0];
        } else {
          setActivatedFilter(null);
        }
        // console.log({
        //   search: textSearch,
        //   activated: _activated,
        //   financerPlan: _plan,
        //   financer: _financer,
        // });

        getPatientsData({
          search: textSearch,
          activated: _activated,
          financerPlan: _plan,
          financer: _financer,
        });
      }
    },
    customToolbar: () => {
      return (
        <Tooltip
          title="Crear Usuario"
          onClick={() => {
            setOpenCreateNewModal(true);
          }}
        >
          <IconButton>
            <AddCircleIcon></AddCircleIcon>
          </IconButton>
        </Tooltip>
      );
    },
  };

  //GET FINANCERS
  const [financersNameList, setFinancersNameList] = useState([]);
  const getFinancersNameList = async (token) => {
    return await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/services/parametrics/api/financers/get-name-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setFinancersNameList(response.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };
  useEffect(() => {
    getFinancersNameList(userToken);
  }, []);

  return (
    <ThemeProvider theme={MuiTheme}>
      <Box
        sx={{
          width: "100%",
          "& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-head": {
            span: {
              justifyContent: "center",
            },
          },
        }}
      >
        <MUIDataTable
          title=""
          data={finalData}
          columns={columns}
          options={options}
        />

        <TablePagination
          component="div"
          count={totalPages}
          page={currentPage}
          onPageChange={(state, number) => {
            getPatientsData({
              search: textSearch,
              activated,
              financerPlan,
              financer,
              page: number,
            });
          }}
          labelRowsPerPage=""
          rowsPerPage={dataLength}
          rowsPerPageOptions={[dataLength]}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`
          }
        />

        <CreatePatient
          open={openCreateNewModal}
          setOpen={setOpenCreateNewModal}
          getPatientsData={() => {
            setLoading(true);
            setTimeout(() => {
              getPatientsData();
              setLoading(false);
            }, 2000);
          }}
        />
        <SeePatientDetailsModal
          open={openDetailsModal}
          setOpen={setOpenDetailsModal}
          handleClose={finishEditPatient}
          patient={user}
        />
        <PaymentDetailsModals
          open={openPaymentModal}
          setOpen={setOpenPaymentModal}
          handleClose={finishEditPayment}
          patient={user}
        />
        <InactivatePatientModal
          open={openInactivationModal}
          setOpen={setOpenInactivationModal}
          setReason={setReason}
          reason={reason}
          handleClose={inactivatePatient}
          patient={user}
        />
      </Box>
    </ThemeProvider>
  );
};
