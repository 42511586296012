import * as Yup from 'yup';

import formModel from './patientFormModel';

const stringRegex = /[a-zA-ZÁÉÍÓÚáéíóú\u00f1\u00d1\u00fa\u00da]+(\s*[a-zA-ZÁÉÍÓÚáéíóú\u00f1\u00d1\u00fa\u00da]*)*[a-zA-ZÁÉÍÓÚáéíóú\u00f1\u00d1\u00fa\u00da]*$]*$/; //NOSONAR

const {
   first_name,
   last_name,
   id_number,
   phone_number,
} = formModel;

const stringErrorMsg = 'Ingresa sólo letras';

// TODO: Update this validations when Backend do.
const patientValidationSchema = Yup.object().shape({
      first_name: Yup.string()
         .matches(stringRegex, stringErrorMsg)
         .required(first_name.requiredErrorMsg),
      last_name: Yup.string()
         .matches(stringRegex, stringErrorMsg)
         .required(last_name.requiredErrorMsg),
      id_number: Yup.string()
         .required(id_number.requiredErrorMsg),
      email: Yup.string().email()
         .required(last_name.requiredErrorMsg),
      phone_number: Yup.string()
         .min(10, phone_number.minErrorMsg)
         .max(10, phone_number.maxErrorMsg)
         .required(phone_number.requiredErrorMsg),
   });

export default patientValidationSchema;
