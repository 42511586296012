import { useState } from "react";
import MUIDataTable, { ExpandButton } from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "../../styles/MuiDataTableStyle";
import {
  Box,
  CircularProgress,
  Fade,
  PaginationItem,
  Popper,
  Toolbar,
} from "@mui/material";
import TablePagination from "@material-ui/core/TablePagination";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import FeedIcon from "@mui/icons-material/Feed";
import { textLabels, downloadOptions } from "../../utils/muiTableOptions";
import { SeeAgreementDetailsModal } from "../Modals/SeeAgreementDetailsModal";
import { useEffect } from "react";
import {
  disableAgreement,
  editAgreement,
  activateAgreement,
} from "../../services/agreementsService";
import { useStateAuthValue } from "../../context/AuthState";
import { useToast } from "../../context/Toast/ToastProvider";
import { showToast, ToastType } from "../../utils/ToastUtil";
import { convertDateFormat } from "../../utils/dateForm";
import {
  deleteFinancerPlan,
  editFinancerPlan,
  getFinancerPlanById,
} from "../../services/financerPlansService";
import CustomToolbar from "./CustomTollbar";
import { NewUserTollbar } from "./NewUserTollbar";
import CreateAgreement from "../../pages/createAgreement";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { createTheme } from "@mui/material";
import { AddCircle, Delete } from "@mui/icons-material";
import CreateFinancerPlan from "../../pages/createFinancerPlan";
import { SeeFinancerPlanDetailsModal } from "../Modals/SeeFinancerPlanDetailsModal";
import { id } from "date-fns/locale";

export const AgreementTable = ({
  data,
  getAgreementsData,
  setLoading,
  totalPages,
  setTotalPages,
  dataLength,
  currentPage,
}) => {
  const [{ userToken }] = useStateAuthValue();
  const toastDispatch = useToast();
  const [finalData, setFinalData] = useState(data);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openCreateNewModal, setOpenCreateNewModal] = useState(false);
  const [openPlanDetailsModal, setOpenPlanDetailsModal] = useState(false);
  const [openCreateNewPlanModal, setOpenCreateNewPlanModal] = useState(false);
  const [financerPlanData, setFinancerPlanData] = useState(null);
  const [openInactivationModal, setOpenInactivationModal] = useState(false);
  const [user, setUser] = useState({});
  const [textSearch, setTextSearch] = useState("");
  const [open, setOpen] = useState(false);

  const [planList, setPlanList] = useState([]);
  const [rowsExpanded, setRowsExpanded] = useState([]);

  useEffect(() => {
    setFinalData(data);
  }, [data]);

  const displayError = (msg) =>
    showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);

  const finishEditAgreement = async (object) => {
    setOpenDetailsModal(false);
    setLoading(true);
    const newData = {
      ...user,
      ...object,
    };

    editAgreement(userToken, newData, displayError)
      .then((e) => {
        if (e != null) {
          showToast(
            toastDispatch,
            "Se edito con exito",
            ToastType.SUCCESS,
            ToastType.SUCCESS
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });

    setLoading(false);
    setUser({});
    setTimeout(() => {
      getAgreementsData();
    }, 1000);
  };

  const finishEditFinancerPlan = async (object) => {
    setOpenPlanDetailsModal(false);
    setLoading(true);

    const newData = {
      ...user,
      ...object,
    };

    editFinancerPlan(userToken, newData, displayError).finally(() => {
      showToast(
        toastDispatch,
        "El plan se edito con exito",
        ToastType.SUCCESS,
        ToastType.SUCCESS
      );
      setLoading(false);
      fetchFinancerPlans(financerPlanData.financerId);
    });

    setLoading(false);
    setLoading(true);
    setUser({});
    setTimeout(() => {
      getAgreementsData();
    }, 1000);
  };

  const activateDisabledAgreement = async (object) => {
    setLoading(true);

    activateAgreement(userToken, object, displayError).finally(() => {
      setLoading(false);
    });

    setTimeout(() => {
      getAgreementsData();
    }, 1000);
  };

  const fetchFinancerPlans = async (id) => {
    const response = await getFinancerPlanById(userToken, id, displayError);

    let _plansList = [];
    response?.map((plan) => {
      _plansList = [..._plansList, plan];
    });

    setPlanList(_plansList);
  };

  const removeFinancerPlan = async (id) => {
    setLoading(true);
    deleteFinancerPlan(userToken, id, displayError).finally(() => {
      showToast(
        toastDispatch,
        "El plan se borro con exito",
        ToastType.SUCCESS,
        ToastType.SUCCESS
      );
      setLoading(false);
      fetchFinancerPlans(financerPlanData.financerId);
    });
    setLoading(false);
  };

  const childTableTheme = createTheme({
    components: {
      MuiTableRow: {
        styleOverrides: {
          root: {
            background: "#ededed",
            textAlign: "center",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            textAlign: "center",
          },
        },
      },
    },
  });

  const columns = [
    {
      name: "financer.financerName",
      label: "Empresa financiadora",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "number",
      label: "Nro.Convenio",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "description",
      label: "Descripcion",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "startDate",
      label: "Fecha Inicio",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <p>
              {convertDateFormat(finalData[dataIndex].startDate).substring(
                0,
                10
              )}
            </p>
          );
        },
      },
    },
    {
      name: "endDate",
      label: "Fecha Fin",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <p>
              {convertDateFormat(finalData[dataIndex].endDate).substring(0, 10)}
            </p>
          );
        },
      },
    },
    {
      name: "status",
      label: "Estado",
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: "acciones",
      label: "Acciones",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div className="icons-container">
              <EditIcon
                id={finalData[dataIndex].usrId}
                className="icon edit-icon"
                onClick={() => {
                  setUser(finalData[dataIndex]);
                  setOpenDetailsModal(true);
                }}
              />
            </div>
          );
        },
      },
    },
  ];

  const options = {
    searchOpen: true,
    selectableRows: "none",
    textLabels,
    downloadOptions,
    filterType: "dropdown",
    responsive: "standard",
    draggableColumns: { enabled: true },
    enableNestedDataAccess: ".",
    page: currentPage,
    count: totalPages,
    serverSide: true,
    pagination: false,
    elevation: 0,
    rowsExpanded: rowsExpanded,
    onSearchChange: (searchQuery) => {
      getAgreementsData({ query: searchQuery });
    },
    onTableChange: (action, tableState) => {
      if (action === "search") {
        setTextSearch(tableState.searchText);
      }
    },
    customToolbar: () => {
      return (
        <NewUserTollbar
          onClickFunc={() => {
            setOpenCreateNewModal(true);
            setCreateAgreementKey(
              (Math.floor(Math.random() * (1000 - 1 + 1)) + 1).toString()
            );
          }}
        />
      );
    },
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    renderExpandableRow: (rowData, rowMeta) => {
      if (planList.length === 0)
        return (
          <TableRow>
            <TableCell
              colSpan={rowData.length + 1}
              style={{ fontWeight: "bold" }}
            >
              <div
                className="icons-container"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: ".5rem",
                }}
              >
                No posee planes
                <AddCircle
                  className="icon edit-icon"
                  onClick={() => {
                    setKey(
                      (Math.floor(Math.random() * (100 - 1 + 1)) + 1).toString()
                    );

                    setOpenCreateNewPlanModal(true);
                  }}
                />
              </div>
            </TableCell>
          </TableRow>
        );
      // retur (
      //   <TableRow>
      //     <TableCell colSpan={colSpan}>
      //       Planes:
      //       {JSON.stringify(planList.map((row) => row.planName))
      //         .replace("[", "")
      //         .replace("]", "")}
      //     </TableCell>
      //   </TableRow>
      // );
      else
        return (
          <ThemeProvider theme={childTableTheme}>
            <TableRow>
              <TableCell colSpan={2} style={{ fontWeight: "bold" }}>
                Nombre de plan
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Consultas por mes
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Cantidad de miembros
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Precio</TableCell>

              <TableCell colSpan={2} style={{ fontWeight: "bold" }}>
                Precio por miembro adicional
              </TableCell>
              {/* <TableCell style={{fontWeight:"bold"}}>Empresa financiadora</TableCell> */}
              <TableCell style={{ fontWeight: "bold" }}>
                <div
                  className="icons-container"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: ".5rem",
                  }}
                >
                  Acciones
                  <AddCircle
                    className="icon edit-icon"
                    onClick={() => {
                      setKey(
                        (
                          Math.floor(Math.random() * (100 - 1 + 1)) + 1
                        ).toString()
                      );

                      setOpenCreateNewPlanModal(true);
                    }}
                  />
                </div>
              </TableCell>
            </TableRow>
            {planList.map((plan) => {
              return (
                <TableRow key={plan.id}>
                  <TableCell colSpan={2}>{plan.planName}</TableCell>
                  <TableCell>{plan.consultationsPerMonth}</TableCell>
                  <TableCell>{plan.familyMembers}</TableCell>
                  <TableCell>{plan.price}</TableCell>

                  <TableCell colSpan={2}>{plan.priceMember}</TableCell>
                  {/* <TableCell>{plan.financer?.financerName}</TableCell> */}
                  <TableCell>
                    <div className="icons-container">
                      <EditIcon
                        className="icon edit-icon"
                        onClick={() => {
                          setFinancerPlanData(plan);

                          // "id": "214a7d41-4e9a-45df-b1b4-6134c397c237",
                          // "planName": "VEB07",
                          // "consultationsPerMonth": 1,
                          // "familyMembers": 0,
                          // "price": 500,
                          // "priceMember": 1500,
                          // "status": "ACTIVE",
                          // "financerId": "93490592-3332-411b-81ec-b13aebf13147",
                          // "agreementId": null,
                          // "planId": null,
                          // "financer": "{businessName: null, commercialAddress: null, creat…}",
                          // "agreement": null

                          setOpenPlanDetailsModal(true);
                        }}
                      />

                      <Delete
                        className="icon edit-icon"
                        onClick={() => {
                          removeFinancerPlan(plan.id);
                        }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </ThemeProvider>
        );
    },
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
      // setRowsExpanded(allExpanded.slice(-1).map(item => item.index));
      setPlanList([]);
      let currentRowData = finalData[curExpanded[0].dataIndex];
      setRowsExpanded(allExpanded.slice(-1).map((item) => item.index));
      setFinancerPlanData({
        agreementId: currentRowData.id,
        financerId: currentRowData.financerId,
        financer: {
          financerName: currentRowData.financer.financerName,
          id: currentRowData.financer.id,
        },
        agreement: {
          description: currentRowData.description,
          id: currentRowData.id,
        },
      });
      setKey((Math.floor(Math.random() * (1000 - 1 + 1)) + 1).toString());
      fetchFinancerPlans(currentRowData.financerId);
    },
  };

  const components = {
    ExpandButton: function (props) {
      return <ExpandButton {...props} />;
    },
  };
  const [key, setKey] = useState(
    (Math.floor(Math.random() * (1000 - 1 + 1)) + 1).toString()
  );
  const [createAgreementKey, setCreateAgreementKey] = useState(
    (Math.floor(Math.random() * (1000 - 1 + 1)) + 1).toString()
  );
  return (
    <ThemeProvider theme={MuiTheme}>
      <Box
        sx={{
          width: "100%",
          "& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-head": {
            span: {
              justifyContent: "center",
            },
          },
          position: "relative",
        }}
      >
        <MUIDataTable
          title=""
          data={finalData}
          columns={columns}
          options={options}
          components={components}
        />
        <TablePagination
          component="div"
          count={totalPages}
          page={currentPage}
          onPageChange={(state, number) => {
            getAgreementsData({ query: textSearch, page: number });
          }}
          labelRowsPerPage=""
          rowsPerPage={dataLength}
          rowsPerPageOptions={[dataLength]}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`
          }
        />
        <CreateAgreement
          open={openCreateNewModal}
          setOpen={setOpenCreateNewModal}
          getAgreementsData={() => {
            setLoading(true);
            setTimeout(() => {
              getAgreementsData();
              setLoading(false);
            }, 2000);
          }}
          key={createAgreementKey}
        />
        <SeeAgreementDetailsModal
          open={openDetailsModal}
          setOpen={setOpenDetailsModal}
          handleClose={finishEditAgreement}
          agreement={user}
        />
        <CreateFinancerPlan
          open={openCreateNewPlanModal}
          setOpen={setOpenCreateNewPlanModal}
          getFinancerPlansData={() => {
            fetchFinancerPlans(financerPlanData.financerId);
          }}
          financerPlanData={financerPlanData}
          key={key}
        />
        <SeeFinancerPlanDetailsModal
          open={openPlanDetailsModal}
          setOpen={setOpenPlanDetailsModal}
          handleClose={finishEditFinancerPlan}
          financerPlan={financerPlanData}
        />
      </Box>
    </ThemeProvider>
  );
};
