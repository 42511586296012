import { useState } from "react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "../../styles/MuiDataTableStyle";
import { Box, Fade, PaginationItem, Popper, Toolbar } from "@mui/material";
import TablePagination from "@material-ui/core/TablePagination";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import { textLabels, downloadOptions } from "../../utils/muiTableOptions";
import { useEffect } from "react";
import { useStateAuthValue } from "../../context/AuthState";
import { useToast } from "../../context/Toast/ToastProvider";
import { showToast, ToastType } from "../../utils/ToastUtil";
import { convertDateFormat } from "../../utils/dateForm";
// import { editPayment } from "../../services/PaymentService";
// import CustomToolbar from "./CustomTollbar";
import { NewUserTollbar } from "./NewUserTollbar";
import CreateFinancer from "../../pages/createFinancer";
import { ImportPatientsModal } from "../Modals/ImportPatientsModal";
import UploadIcon from "@mui/icons-material/Upload";
import EditFinancer from "../../pages/editFinancer";

export const FinancerTable = ({
  data,
  getFinancersData,
  setLoading,
  totalPages,
  setTotalPages,
  dataLength,
  currentPage,
}) => {
  const [{ userToken }] = useStateAuthValue();
  const toastDispatch = useToast();
  const [finalData, setFinalData] = useState(data);
  const [importPatientsModal, setImportPatientsModal] = useState(false);
  const [openCreateNewModal, setOpenCreateNewModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [user, setUser] = useState({});
  const [textSearch, setTextSearch] = useState("");
  const [reason, setReason] = useState("");

  useEffect(() => {
    setFinalData(data);
  }, [data]);

  const displayError = (msg) =>
    showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);

  const columns = [
    {
      name: "created_date",
      label: "Fecha de alta",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <p>
              {convertDateFormat(finalData[dataIndex].createdDate).substring(
                0,
                10
              )}
            </p>
          );
        },
      },
    },
    {
      name: "financerName",
      label: "Empresa",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "acciones",
      label: "Acciones",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div className="icons-container">
              <UploadIcon
                id={finalData[dataIndex].usrId}
                className="icon upload-icon"
                onClick={() => {
                  setUser(finalData[dataIndex]);
                  setImportPatientsModal(true);
                }}
              />
              <EditIcon
                id={finalData[dataIndex].usrId}
                onClick={() => {
                  setUser(finalData[dataIndex]);
                  setOpenEditModal(true);
                }}
                style={{ marginLeft: ".5rem", cursor: "pointer" }}
                
              />
            </div>
          );
        },
      },
    },
  ];

  const options = {
    searchOpen: true,
    selectableRows: "none",
    textLabels,
    downloadOptions,
    filterType: "dropdown",
    responsive: "standard",
    draggableColumns: { enabled: true },
    enableNestedDataAccess: ".",
    page: currentPage,
    count: totalPages,
    serverSide: true,
    pagination: false,
    elevation: 0,
    onSearchChange: (searchQuery) => {
      getFinancersData({ query: searchQuery });
    },
    onTableChange: (action, tableState) => {
      if (action === "search") {
        setTextSearch(tableState.searchText);
      }
    },
    customToolbar: () => {
      return (
        <NewUserTollbar
          onClickFunc={() => {
            setOpenCreateNewModal(true);
          }}
        />
      );
    },
  };

  function quitarNull(objeto) {
    const newObjeto = {};
    for (const key in objeto) {
      if (objeto.hasOwnProperty(key)) {
        newObjeto[key] = objeto[key] === null ? "" : objeto[key];
      }
    }
    return newObjeto;
  }
  return (
    <ThemeProvider theme={MuiTheme}>
      <Box
        sx={{
          width: "100%",
          "& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-head": {
            span: {
              justifyContent: "center",
            },
          },
        }}
      >
        <MUIDataTable
          title=""
          data={finalData}
          columns={columns}
          options={options}
        />
        <TablePagination
          component="div"
          count={totalPages}
          page={currentPage}
          onPageChange={(state, number) => {
            getFinancersData({ query: textSearch, page: number });
          }}
          labelRowsPerPage=""
          rowsPerPage={dataLength}
          rowsPerPageOptions={[dataLength]}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`
          }
        />
        <CreateFinancer
          open={openCreateNewModal}
          setOpen={setOpenCreateNewModal}
          getFinancersData={() => {
            setLoading(true);
            setTimeout(() => {
              getFinancersData();
              setLoading(false);
            }, 2000);
          }}
        />
        <EditFinancer
          open={openEditModal}
          setOpen={setOpenEditModal}
          getFinancersData={() => {
            setLoading(true);
            setTimeout(() => {
              getFinancersData();
              setLoading(false);
            }, 2000);
          }}
          financerData={quitarNull(user)}
        />
        <ImportPatientsModal
          open={importPatientsModal}
          setOpen={setImportPatientsModal}
          // handleClose={finishEditPatient}
          financer={user}
        />
      </Box>
    </ThemeProvider>
  );
};
