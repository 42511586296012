import { useEffect, useState } from "react";
import { getReportsErrorDatos } from "../services/reportsService";
import { useStateAuthValue } from "../context/AuthState";
import { ToastType, showToast } from "../utils/ToastUtil";
import { useToast } from "../context/Toast/ToastProvider";

const ErrorDatos = () => {
  const [{userToken}] = useStateAuthValue();
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [name, setName] = useState(null);
  const [url, setUrl] = useState(null);
  const toastDispatch = useToast();
  const displayError = (msg) => showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);

  const getReportsData = async () => {
    const response = await getReportsErrorDatos(userToken, displayError);
    setReports(response);
    setLoading(true);
  }

  useEffect(() => {
    if (userToken) {
      getReportsData();
      if (reports) {
        setName(reports[0]?.name);
        setUrl(reports[0]?.url);
      }
    };
  }, [loading, userToken]);

  const getIframe = () => {
    return (
        <iframe
            title={name}
            src={url}
            className="iframe-report"
            frameBorder="0"
            width="100%"
            height="100%"
            allowtransparency="true">
        </iframe>
    );
  }

  return (
    <div className="report">
        {getIframe()}
    </div>
  );
};

export default ErrorDatos;