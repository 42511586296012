import React from "react";
import TextField from "@material-ui/core/TextField";
import { Field, ErrorMessage } from "formik";
const FormikField = ({
  name,
  label,
  type = "text",
  fullWidth = true,
  size = "medium",
  isShrink = false,
  errorMsg = null,
  ...props
}) => {
  return (
    <div className="FormikField">
      <Field
        autoComplete="off"
        label={label}
        fullWidth={fullWidth}
        as={TextField}
        name={name}
        type={type}
        size={size}
        helperText={
          <>
            <span style={{ color: "red" }}>{errorMsg && errorMsg +" "}</span>
            <ErrorMessage name={name}>
              {(msg) => <span style={{ color: "red" }}>{msg}</span>}
            </ErrorMessage>
          </>
        }
        InputLabelProps={isShrink ? { shrink: isShrink } : null}
        {...props}
      />
    </div>
  );
};

export default FormikField;
