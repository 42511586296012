import { useState } from "react";
import Box from "@mui/material/Box";
import { Button } from "@material-ui/core";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { CardContent } from "@mui/material";
import { Formik, Form, useFormik  } from "formik";
import Card from "@mui/material/Card";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import {
  patientInitialValues,
  patientFormModel,
  patientValidationSchema,
} from "./formModel";
import PlanTable from "./planTable";
import PersonalInformationPatientForm from "./personalInfo";
import SendLink from "./senLink";
import medilinePlans from "../../utils/mockMedilinePlans";
import { useNavigate } from "react-router-dom";

const NewClientForm = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(1);
  const [clientValues, setClientValues] = useState(1);
  const steps = ["Selección del plan", "Información Personal", "Enviar Link"];

  const submit = () => {
    if (activeStep !== 3) {
      setActiveStep(activeStep + 1);
    } else {
      console.log("submit");
      navigate("/")
    }
  };

    const formik = useFormik({
      validationSchema: patientValidationSchema,
    });


  const Result = (step) => {
    switch (step) {
      case 1:
        return (
          <PlanTable
            handleClick={(id) => {
              const plan = medilinePlans.filter((item) => item.id === id);
              setClientValues((prevState) => {
                return {...prevState, plan: plan[0]}})
              submit();
            }}
          />
        );
      case 2:
        return <PersonalInformationPatientForm formModel={patientFormModel} clientValues={clientValues}/>;
      case 3:
        return <SendLink clientValues={clientValues} submit={submit}/>;
      default:
        return <div></div>;
    }
  };

  return (
    <Card className="card">
      <CardContent>
        <div className="title-card" style={{ marginBottom: "10px" }}>
          <div className="icon">
            <GroupsOutlinedIcon style={{ fontSize: "45px" }} />
          </div>
          <h4>Nuevo Cliente</h4>
        </div>
        <Box
          sx={{
            width: "100%",
            "& .MuiStepper-root .MuiStep-horizontal .MuiStepLabel-root .MuiStepLabel-iconContainer .MuiSvgIcon-root":
              {
                color: "rgb(219 35 35)",
              },
            "& .MuiStepper-root .MuiStep-horizontal .MuiStepLabel-root .Mui-completed .MuiSvgIcon-root":
              {
                color: "#002c54",
              },
            "& .MuiStepper-root .MuiStep-horizontal .MuiStepLabel-root .MuiStepLabel-labelContainer .MuiStepLabel-label":
              {
                color: "#002c54",
                fontWeight: "600",
              },
            "& .MuiStepper-root .MuiStep-horizontal .MuiStepConnector-root .MuiStepConnector-line":
              {
                borderTopWidth: "2px",
                borderColor: "#c5c5c5",
              },
          }}
        >
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Formik
            initialValues={patientInitialValues}
            validationSchema={patientValidationSchema}
            enableReinitialize
            onSubmit={(values) => {
              setClientValues((prevState) => {return {...prevState,...values}})
              submit(values)
            }}
          >
            {({ values, errors }) => {
              return(
              <Form>
                {Result(activeStep)}
                {activeStep !== 1 && (
                  <div className="btn-form-wrapper">
                    <Button
                      onClick={() => setActiveStep(activeStep - 1)}
                      variant="outlined"
                      className={`btn btn--back`}
                    >
                      Volver
                    </Button>

                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        className={`btn red-btn`}
                        type="submit"
                        onClick={() => {
                          if(errors !== {}) {
                            formik.validateForm();
                            return;
                          } else {
                            setClientValues((prevState) => {return {...prevState,...values}})
                            submit(values)
                          }}}
                      >
                        {activeStep === 3 ? "Finalizar" : "Siguiente"}
                      </Button>
                    </>
                  </div>
                )}
              </Form>
            )}}
          </Formik>
        </Box>
      </CardContent>
    </Card>
  );
};

export default NewClientForm;
