import { Box, IconButton, ThemeProvider, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import React, { useEffect, useRef, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { MuiTheme } from "../styles/MuiDataTableStyle";
import { useToast } from "../context/Toast/ToastProvider";
import { ToastType, showToast } from "../utils/ToastUtil";
import { useStateAuthValue } from "../context/AuthState";
import { BaseModal } from "../components/Modals/BaseModal";
import { Formik } from "formik";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { translations } from "../components/Modals/ImportPatientsModalTranslation";
import { importPatientsGigaRed } from "../services/patientsService";
import { getReportsGigaRed } from "../services/reportsService";
import encode from "jwt-encode";
import { getFormattedCurrentDate } from "../utils/dateNow";

const GigaRed = () => {
  const [importPayrollModalOpen, setimportPayrollModalOpen] = useState(false);
  const [{ userToken }] = useStateAuthValue();
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [name, setName] = useState(null);
  const [url, setUrl] = useState(null);
  const toastDispatch = useToast();
  const displayError = (msg) =>
    showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);

  const getReportsData = async () => {
    const response = await getReportsGigaRed(userToken, displayError);
    console.log(response);
    setReports(response);
    setLoading(true);
  };
  const embeber = (token, dashboard, from, to) => {
    if (!token || !dashboard) return;
    const payload = {
      resource: { dashboard: dashboard },
      params: {},
      exp: Math.round(Date.now() / 1000) + 600, // 10 minutos de expiración
    };
    const tokenJWT = encode(payload, token);
    const url =
      process.env.REACT_APP_API_URL +
      `/embed/dashboard/${tokenJWT}#bordered=true&titled=true&fecha_desde=${from}&fecha_hasta=${to}`;
    return url;
  };
  useEffect(() => {
    if (userToken) {
      getReportsData();
      if (reports) {
        setName(reports[0]?.name);
        setUrl(
          embeber(
            reports[0]?.secrey_key,
            parseInt(reports[0]?.remote_id, 10),
            getFormattedCurrentDate(0, "01"),
            getFormattedCurrentDate(0, null)
          )
        );
      }
    }
  }, [loading, userToken]);
  const getIframe = () => {
    return (
      <iframe
        title={name}
        src={url}
        className="iframe-report"
        frameBorder="0"
        width="100%"
        height="100%"
        allowtransparency="true"
      ></iframe>
    );
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <div className="gigared-payroll">
        <div className="gigared-payroll-container">
          <Box
            sx={{
              background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
              padding: "10px",
              borderRadius: "15px",
              color: "white",
              height: "80px",
              display: "flex",
              alignItems: "center",
              width: "90%",
              position: "relative",
              bottom: "25px",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: " 700",
                letterSpacing: " 1px",
              }}
            >
              GigaRed
            </Typography>
          </Box>
          <div className="report">{getIframe()}</div>
          {/* <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Upload Nro.</TableCell>
                  <TableCell align="right">Fecha Hora</TableCell>
                  <TableCell align="right">Nombre Archivo</TableCell>
                  <TableCell align="right">Cant.Registros</TableCell>
                  <TableCell align="right">
                    Acciones{" "}
                    <span>
                      <IconButton
                        onClick={() => {
                          setimportPayrollModalOpen(true);
                        }}
                      >
                        <AddCircleIcon></AddCircleIcon>
                      </IconButton>
                    </span>{" "}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[].map((row) => (
                  <TableRow
                    key={row.uploadNro}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.uploadNro}
                    </TableCell>
                    <TableCell align="right">{row.dateTime}</TableCell>
                    <TableCell align="right">{row.fileName}</TableCell>
                    <TableCell align="right">{row.recordsAmount}</TableCell>
                    <TableCell align="right">OK</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> */}
          {/* <ImportPayrollModal
            open={importPayrollModalOpen}
            setOpen={(open) => {
              setimportPayrollModalOpen(open);
            }}
          /> */}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default GigaRed;
const ImportPayrollModal = ({ open, setOpen, financer }) => {
  const toastDispatch = useToast();
  const showError = (msgError) => {
    showToast(toastDispatch, msgError, ToastType.ERROR, ToastType.ERROR);
  };
  const [{ userToken }] = useStateAuthValue();
  const formikSetValues = useRef(null);

  const fields = [
    {
      label: "Nro. Cliente",
      key: "clientNumber",
      alternateMatches: ["cliente_nro", "clienteNro", "numero_cliente"],
      fieldType: {
        type: "input",
      },
      validations: [
        {
          rule: "required",
          errorMessage: "Numero de cliente requerido",
          level: "error",
        },
      ],
    },
    {
      label: "Documento",
      key: "idNumber",
      alternateMatches: [
        "idnumber",
        "idNumber",
        "documento",
        "dni",
        "DNI",
        "nrodoc",
      ],
      fieldType: {
        type: "input",
      },
      validations: [
        {
          rule: "required",
          errorMessage: "Documento requerido",
          level: "error",
        },
      ],
    },
    {
      label: "Apellido",
      key: "lastName",
      alternateMatches: ["apellido", "lastName", "lastname"],
      fieldType: {
        type: "input",
      },
      validations: [
        {
          rule: "required",
          errorMessage: "Apellido requerido",
          level: "error",
        },
      ],
    },
    {
      label: "Nombre",
      key: "firstName",
      alternateMatches: ["nombre", "name", "firstName"],
      fieldType: {
        type: "input",
      },
      validations: [
        {
          rule: "required",
          errorMessage: "Nombre requerido",
          level: "error",
        },
      ],
    },
    {
      label: "Correo",
      key: "email",
      alternateMatches: ["email", "mail", "correo"],
      fieldType: {
        type: "input",
      },
    },
    {
      label: "Celular",
      key: "phoneNumber",
      alternateMatches: [
        "Celular",
        "CELULAR",
        "celular",
        "phone",
        "cellphone",
        "phone_number",
        "phoneNumber",
        "telefono",
      ],
      fieldType: {
        type: "input",
      },
      validations: [
        {
          rule: "required",
          errorMessage: "Correo requerido",
          level: "error",
        },
      ],
    },
  ];

  const displayError = (msg) =>
    showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = (data, file) => {
    setOpen(false);
    console.log(data);
    console.log(file);
    let sendData = {
      data: JSON.stringify(data.validData),
      filename: file.name,
      size: file.size,
      financerId: "a97cd7fb-37e8-4a9d-80be-807261b0a539",
    };
    console.log("sendData", sendData);
    importPatientsGigaRed(userToken, sendData, displayError).then((e) => {
      if (e != null) {
        showToast(
          toastDispatch,
          "Se creo con exito",
          ToastType.SUCCESS,
          ToastType.SUCCESS
        );
      }
    });
  };

  return (
    <div>
      <BaseModal open={open} setOpen={setOpen}>
        <Formik>
          {({ isValid, values, setValues }) => {
            formikSetValues.current = setValues;
            return (
              <ReactSpreadsheetImport
                isOpen={open}
                onClose={onClose}
                onSubmit={onSubmit}
                fields={fields}
                translations={translations}
              />
            );
          }}
        </Formik>
      </BaseModal>
    </div>
  );
};
