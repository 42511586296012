import axios from "axios";

const notificationsUrl = `${process.env.REACT_APP_BASE_URL}/services/notification/api/notification-deliveries`;

export const getNotifications = async (
  token,
  displayError = () => {},
  params
) => {
  let url = `${notificationsUrl}/type/?search=${
    params?.search ? params?.search : ""
  }&page=${params?.page ? params?.page : ""}&size=${
    params?.size ? params?.size : ""
  }&meanTypes=${params?.meanTypes ? params?.meanTypes : []}&status=${
    params?.status ? params?.status : ""
  }&event=${params?.event ? params?.event : ""}`;
  return await axios
    .get(url, { headers: { authorization: `Bearer ${token}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("getNotifications error: " + error.message);
    });
};

export const resetNotification = async (
  token,
  displayError = () => {},
  params
) => {
   let url = `${notificationsUrl}/reset/${params?.id}`
  return await axios
    .get(url, { headers: { authorization: `Bearer ${token}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("resetNotification error: " + error.message);
    });
};
