import axios from "axios";

const financersUrl = `${process.env.REACT_APP_BASE_URL}/services/parametrics/api/financers/backoffice`;
const financersBaseUrl = `${process.env.REACT_APP_BASE_URL}/services/parametrics/api/financers`;

export const getFinancers = async (token, displayError = () => {}, params) => {
  let url = `${financersUrl}${params?.query || params?.page ? "?" : ""}${
    params?.query && params?.query !== "" ? `search=` + params?.query : ``
  }${params?.query && params?.page ? `&` : ""}${
    params?.page ? `page=` + params?.page : ``
  }`;
  return await axios
    .get(url, { headers: { authorization: `Bearer ${token}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("getFinancers error: " + error.message);
    });
};

export const getAllFinancers = async (token, displayError = () => {}, params) => {
  let url = `${financersBaseUrl}`;
  return await axios
    .get(url, { headers: { authorization: `Bearer ${token}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("getAllFinancers error: " + error.message);
    });
};

export const editFinancer = async (token, data, displayError = () => {}) => {
  return await axios
    .put(`${financersBaseUrl}/${data?.id}`, data, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("editFinancer error: " + error.message);
    });
};

// export const activateFinancer = async (token, data, displayError = () => {}) => {
//   return await axios
//     .post(
//       `${financersUrl}/${data?.id}`,
//       { ...data, activated: true },
//       { headers: { authorization: `Bearer ${token}` } }
//     )
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       if (token) displayError("activateFinancer error: " + error.message);
//     });
// };

// export const disableFinancer = async (
//   token,
//   id,
//   reason,
//   displayError = () => {}
// ) => {
//   return await axios
//     .delete(`${financersUrl}/${id}/${reason}`, {
//       headers: { authorization: `Bearer ${token}` },
//     })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       if (token) displayError("disableFinancer error: " + error.message);
//     });
// };

export const createFinancer = async (token, data, displayError = () => {}) => {
  return await axios
    .post(`${financersBaseUrl}`, data, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("createFinancer error: " + error.message);
    });
};
