import React from "react";
import axios from "axios";

const emailBlacklistBaseUrl = `${process.env.REACT_APP_BASE_URL}/services/userprofile/api/patients/backoffice/email-blacklist`;
export const getEmailBlacklist = async (token, email) => {
    let urlFinal = `${emailBlacklistBaseUrl}?email=${email}`
  return await axios
    .get(urlFinal, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
    //   if (token) displayError("getPlans error: " + error.message);
      if (token) return error.message;
    });
};
export const saveEmailBlacklist = async (token, emailBlackList) => {
    let urlFinal = `${emailBlacklistBaseUrl}?email=${emailBlackList.email}${emailBlackList.id != null && emailBlackList.id != ""  ? "&id="+emailBlackList.id : "" }`
  return await axios
    .post(urlFinal,{},{
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
    //   if (token) displayError("getPlans error: " + error.message);
      if (token) return error;
    });
};
export const deleteEmailBlacklist = async (token, emailBlackList) => {
    let urlFinal = `${emailBlacklistBaseUrl}?email=${emailBlackList.email}${emailBlackList.id != null && emailBlackList.id != ""  ? "&id="+emailBlackList.id : "" }`
  return await axios
    .delete(urlFinal,{
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
    //   if (token) displayError("getPlans error: " + error.message);
      if (token) return error;
    });
};