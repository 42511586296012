import * as React from "react";
import Button from "@mui/material/Button";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { FormikField, SelectObjectField } from "../FormFields";
import { BaseModal } from "./BaseModal";
import { useState } from "react";
import { useToast } from "../../context/Toast/ToastProvider";
import { ToastType, showToast } from "../../utils/ToastUtil";
import { useEffect } from "react";
import { useStateAuthValue } from "../../context/AuthState";
import {
  getPrepaidHealth,
  getPrepaidHealthCategoryByPrepaidHealth,
} from "../../services/prepaidHealthService";
import { useRef } from "react";
import { getFinancerPlans, getPatient } from "../../services/patientsService";

export const SeePatientDetailsModal = ({
  open,
  setOpen,
  handleClose,
  patient,
}) => {
  const FormScheme = Yup.object().shape({
    lastName: Yup.string().required("No debe quedar vacío"),
    firstName: Yup.string().required("No debe quedar vacío"),
    id_number: Yup.string().required("No debe quedar vacío"),
    email: Yup.string()
      .email("Correo inválido")
      .required("No debe quedar vacío"),
    phone: Yup.string().required("No debe quedar vacío"),
    sex: Yup.string().required("No debe quedar vacío"),
  });
  const [prepaidHealthList, setPrepaidHealthList] = useState([]);
  const [prepaidHealth, setPrepaidHealth] = useState("");
  const [prepaidHealthCategoryList, setPrepaidHealthCategoryList] = useState(
    []
  );
  const [prepaidHealthCategory, setPrepaidHealthCategory] = useState("");
  const [emptyPrepaidHealthCategoryList, setEmptyPrepaidHealthCategoryList] =
    useState(false);
  const toastDispatch = useToast();
  const showError = (msgError) => {
    showToast(toastDispatch, msgError, ToastType.ERROR, ToastType.ERROR);
  };
  const [{ userToken }] = useStateAuthValue();
  const formikSetValues = useRef(null);

  const [financerPlansList, setFinancerPlansList] = useState([]);
  const [currentFinancerPlan, setCurrentFinancerPlan] = useState({});
  const sexList = [
    {
      name: `Hombre`,
      id: `MALE`,
    },
    {
      name: `Mujer`,
      id: `FEMALE`,
    },
    {
      name: `No binario`,
      id: `NON_BINARY`,
    },
  ];
  const fetchFinancerPlans = async () => {
    const response = await getFinancerPlans(userToken, showError);
    let _financerPlansList = [];
    //console.log(response);
    response?.data?.map((plan) => {
      if (plan.financerId == "32333636-3631-3338-2d33-3536362d3336") {
        _financerPlansList = [
          ..._financerPlansList,
          {
            name: `${plan.planName}`,
            id: plan.id,
          },
        ];
      }
    });
    let _agreementFinancerPlansList = [];
    response?.data?.map((plan) => {
      if (plan.financerId != "32333636-3631-3338-2d33-3536362d3336") {
        _agreementFinancerPlansList = [
          ..._agreementFinancerPlansList,
          {
            name: `${plan.planName} - ${plan.financer.financerName}`,
            id: plan.id,
          },
        ];
      }
    });
    _agreementFinancerPlansList.sort((a, b) => {
      // Comparamos los nombres en minúsculas para asegurar una comparación insensible a mayúsculas/minúsculas
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (nameA < nameB) {
        return -1; // nameA debe ir antes que nameB
      }
      if (nameA > nameB) {
        return 1; // nameA debe ir después que nameB
      }
      return 0; // Los nombres son iguales
    });
    _financerPlansList = [
      ..._financerPlansList,
      ..._agreementFinancerPlansList,
    ];
    setFinancerPlansList(_financerPlansList);
  };

  useEffect(() => {
    fetchFinancerPlans();
  }, []);

  const renderExtraLabel = () => {
    if (prepaidHealth) {
      if (emptyPrepaidHealthCategoryList) {
        return "No hay planes para esta obra social";
      } else if (prepaidHealth && !prepaidHealthCategory) {
        return "El plan es requerido";
      } else {
        return "";
      }
    } else {
      return "Seleccioná primero tu obra social";
    }
  };

  const fetchPrepaidHealthCategoryList = async (prepaidHealthSelected) => {
    const response = await getPrepaidHealthCategoryByPrepaidHealth(
      userToken,
      prepaidHealthSelected,
      showError
    );
    setPrepaidHealthCategoryList(response?.data);
    setEmptyPrepaidHealthCategoryList(response?.data?.length === 0);
  };

  const fetchPrepaidHealthList = async () => {
    const response = await getPrepaidHealth(userToken, showError);
    setPrepaidHealthList(response?.data);
  };

  useEffect(() => {
    if (prepaidHealth && prepaidHealth !== "") {
      fetchPrepaidHealthCategoryList(prepaidHealth?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prepaidHealth]);

  useEffect(() => {
    if (prepaidHealth && prepaidHealth !== "") {
      if (prepaidHealth.name === "PARTICULAR") {
        setPrepaidHealthCategory(prepaidHealthCategoryList[0]);
        if (
          formikSetValues?.current !== undefined &&
          typeof formikSetValues.current === "function"
        ) {
          formikSetValues.current((prevValues) => ({
            ...prevValues,
            affiliate_number: "",
          }));
        }
      }
    }
  }, [prepaidHealth, prepaidHealthCategoryList]);

  useEffect(() => {
    if (prepaidHealthCategory || prepaidHealth) {
      const prepaidValues = {
        id: prepaidHealthCategory?.id,
        name: prepaidHealthCategory?.name,
        prepaidHealth: {
          id: prepaidHealth?.id,
          name: prepaidHealth?.name,
        },
      };
      if (
        formikSetValues?.current !== undefined &&
        typeof formikSetValues.current === "function"
      ) {
        formikSetValues.current((prevValues) => ({
          ...prevValues,
          prepaid_health_category: prepaidValues,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prepaidHealthCategory, prepaidHealth]);

  useEffect(() => {
    fetchPrepaidHealthList();
    if (patient?.prepaid_health_category) {
      setPrepaidHealthCategory(patient?.prepaid_health_category || "");
      setPrepaidHealth(patient?.prepaid_health_category?.prepaidHealth || "");
    } else {
      setPrepaidHealthCategory("");
      setPrepaidHealth("");
    }
    console.log(patient);
  }, [patient]);

  return (
    <div>
      <BaseModal open={open} setOpen={setOpen}>
        <Formik
          initialValues={{
            lastName: patient?.lastName || "",
            firstName: patient?.firstName || "",
            email: patient?.email || "",
            id_number: patient?.id_number || "",
            plan_id: patient?.plan_id || "",
            affiliate_number: patient?.affiliate_number || "",
            phone: patient?.phone || "",
            sex: patient?.sex || "",
          }}
          validationSchema={FormScheme}
        >
          {({ isValid, values, setValues }) => {
            formikSetValues.current = setValues;
            return (
              <Form>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2,1fr)",
                    gridGap: "25px",
                  }}
                >
                  <FormikField name="lastName" label="Apellido" />
                  <FormikField name="firstName" label="Nombre" />
                  <FormikField name="id_number" label="DNI" />
                  <FormikField name="email" label="Correo" />
                  <FormikField name="phone" label="Celular" />
                  <SelectObjectField
                    label="Plan"
                    setValue={(e) => {
                      console.log(
                        financerPlansList.filter(
                          (fp) => fp.id == values.plan_id
                        )[0]
                      );
                      setValues({ ...values, plan_id: e.id });
                      //setCurrentFinancerPlan(e);
                    }}
                    value={
                      financerPlansList.filter(
                        (fp) => fp.id == values.plan_id
                      )[0]
                    }
                    data={financerPlansList || []}
                    className="field"
                    //errorMsg={errors.financer_plan}
                    //isError={errors.financer_plan}
                  />
                  <SelectObjectField
                    label="Obra Social o Prepaga"
                    value={prepaidHealth}
                    setValue={(e) => {
                      if (prepaidHealth !== e) setPrepaidHealthCategory("");
                      setPrepaidHealth(e);
                    }}
                    data={prepaidHealthList || []}
                    className="field"
                  />
                  <SelectObjectField
                    label="Plan obra social"
                    defaultValue=""
                    value={prepaidHealthCategory}
                    setValue={setPrepaidHealthCategory}
                    data={prepaidHealthCategoryList || []}
                    className="field"
                    disabled={!prepaidHealth || emptyPrepaidHealthCategoryList}
                    extraLabel={renderExtraLabel()}
                  />
                  <FormikField name="affiliate_number" label="N° de asociado" />
                  <SelectObjectField
                    label="Sexo"
                    setValue={(e) => {
                      //console.log(financerPlansList.filter((fp) => fp.id == values.plan_id)[0]);
                      setValues({ ...values, sex: e.id });
                      //setCurrentFinancerPlan(e);
                    }}
                    value={
                      sexList.filter(
                        (fp) => fp.id == values.sex
                      )[0]
                    }
                    data={sexList}
                    className="field"
                    //errorMsg={errors.financer_plan}
                    //isError={errors.financer_plan}
                  />
                  <></>
                </div>
                <div style={{ display: "flex" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#DA2037",
                      margin: "30px 10px 0 0",
                      borderRadius: "20px",
                      texttransform: "capitalize",
                    }}
                    onClick={() => setOpen(false)}
                  >
                    Cancelar
                  </Button>
                  <Button
                    disabled={
                      !isValid || prepaidHealth === ""
                        ? false
                        : prepaidHealthCategory === ""
                        ? true
                        : prepaidHealth.name === "PARTICULAR"
                        ? false
                        : values.affiliate_number === ""
                        ? true
                        : false
                    }
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#002c54",
                      margin: "30px 10px 0 0",
                      borderRadius: "20px",
                      texttransform: "capitalize",
                    }}
                    onClick={() => {
                      handleClose(values);
                    }}
                  >
                    Guardar
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </BaseModal>
    </div>
  );
};
