import axios from "axios";

const patientsUrl = `${process.env.REACT_APP_BASE_URL}/services/userprofile/api/patients/backoffice`;
const patientsBaseUrl = `${process.env.REACT_APP_BASE_URL}/services/userprofile/api`;

export const getPatients = async (token, displayError = () => {}, params) => {
  // let url = `${patientsUrl}${params?.query || params?.page ? "?" : ""}${
  //   params?.query && params?.query !== "" ? `search=` + params?.query : ``
  // }${params?.query && params?.page ? `&` : ""}${
  //   params?.page ? `page=` + params?.page : ``
  //   }`;
  let url = `${patientsUrl}?search=${
    params?.search ? params?.search : ""
  }&activated=${
    params?.activated ? params?.activated == "ACTIVO" : ""
  }&financerPlan=${
    params?.financerPlan ? params?.financerPlan : ""
  }&financer=${
    params?.financer ? params?.financer : ""
  }&page=${
    params?.page ? params?.page : ""
  }&size=${
    params?.size ? params?.size : ""
  }`;
  return await axios
    .get(url, { headers: { authorization: `Bearer ${token}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("getPatients error: " + error.message);
    });
};

export const editPatient = async (token, data, displayError = () => {}) => {
  return await axios
    .put(`${patientsUrl}/${data?.id}`, data, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("editPatient error: " + error.message);
    });
};

export const activatePatient = async (token, data, displayError = () => {}) => {
  return await axios
    .post(
      `${patientsUrl}/${data?.id}`,
      { ...data, activated: true },
      { headers: { authorization: `Bearer ${token}` } }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("activatePatient error: " + error.message);
    });
};
export const disablePatient = async (
  token,
  id,
  reason,
  displayError = () => {}
) => {
  return await axios
    .delete(`${patientsUrl}/${id}/${reason}`, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("disablePatient error: " + error.message);
    });
};

export const createPatient = async (token, data, displayError = () => {}) => {
  return await axios
    .post(`${patientsUrl}/create`, data, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);

      if (token) {
        // Encontramos la posición inicial del substring
        const startIndex = error.response.data.title.indexOf("[{");
        // Encontramos la posición final del substring
        const endIndex = error.response.data.title.indexOf("}]") + 2;
        // Extraemos el substring
        const jsonString = error.response.data.title.substring(
          startIndex,
          endIndex
        );

        // Convertimos el string JSON a un objeto JavaScript
        const errorObject = JSON.parse(jsonString);
        console.log(errorObject);

        if (errorObject != null) {
          displayError(
            "Error Usuario Bonificado: " + errorObject[0].fieldErrors[0].message
          );
        } else displayError("Error Usuario Bonificado: " + error.message);
      }
    });
};

export const validatePatientExist = async (
  token,
  mail,
  dni,
  displayError = () => {}
) => {
  return await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/services/userprofile/api/t-motos/validar-usuario?dni=${dni}&mail=${mail}`,
      {
        headers: { authorization: `Bearer ${token}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("activatePatient error: " + error.message);
    });
};

const parametricsUrl = `${process.env.REACT_APP_BASE_URL}/services/parametrics/api`;
export const getFinancerPlans = async (token, displayError = () => {}) => {
  let url = `${parametricsUrl}/financer-plans`;
  return await axios
    .get(url, { headers: { authorization: `Bearer ${token}` } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (token) displayError("getFinancerPlans error: " + error.message);
    });
};



export const importPatients = async (token, data, displayError = () => {}) => {
  console.log(data);
  return await axios
    .post(`${patientsBaseUrl}/patient-import-file`, data, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("importPatient error: " + error.message);
    });
};

export const importPatientsGigaRed = async (token, data, displayError = () => {}) => {
  console.log(data);
  return await axios
    .post(`${patientsBaseUrl}/patient-import-file-gigared`, data, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (token) displayError("importPatient error: " + error.message);
    });
};