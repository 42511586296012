import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  ThemeProvider,
  Typography,
} from "@mui/material";

import { MuiTheme } from "../styles/MuiDataTableStyle";
import Reports from "./reports";
import { useStateReportValue } from "../context/Reports";
import { element } from "prop-types";
import encode from "jwt-encode";
import { useLocation } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { getAllReports } from "../services/reportsService";
import { useStateAuthValue } from "../context/AuthState";
import { showToast, ToastType } from "../utils/ToastUtil";
import { useToast } from "../context/Toast/ToastProvider";

const TmotosDashboard = () => {
  const [isShowingDashboard, setisShowingDashboard] = useState(true);
  //const [{ reports }] = useStateReportValue();
  const [reports, setReports] = useState([])
  const [url, setUrl] = useState("");
  const [currentView, setcurrentView] = useState(null);
  const [{userToken}] = useStateAuthValue();
  const toastDispatch = useToast();

  const embeber = (token, dashboard) => {
    const payload = {
      resource: { dashboard: dashboard },
      params: {},
      exp: Math.round(Date.now() / 1000) + 6000, // 10 minutos de expiración
    };
    const tokenJWT = encode(payload, token);
    const url =
      process.env.REACT_APP_API_URL +
      `/embed/dashboard/${tokenJWT}#bordered=true&titled=true`;
    return url;
  };
  const displayError = (msg) =>
    showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);
  useEffect(() => {
    getReports();
    GetLocation();
  }, []);
  const getReports = async () => {
    let reportsGet = await getAllReports(userToken, displayError);
    console.log(reportsGet);
    
    setReports(reportsGet);
  }
  const GetLocation = () => {
    const urlParams = new URLSearchParams(window.location.search);

    // Obtener el valor del parámetro "param"
    const paramValue = urlParams.get("param");

    setcurrentView(paramValue);
    if (paramValue) {
      let report = reports.find((element) => element.name === paramValue);
      setUrl(embeber(report.secrey_key, parseInt(report.remote_id, 10)));
    }
  };
  const miRef = useRef(null);

  const centrarComponente = () => {
    miRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };
  const handleChangeView = (viewName) => {
    setisShowingDashboard(false);
    // Crear una instancia del objeto URL basado en la URL actual
    const url = new URL(window.location);

    // Agregar o actualizar el parámetro
    url.searchParams.set("param", viewName);

    // Actualizar la URL en el navegador sin recargar la página
    window.history.pushState({}, "", url);

    let report = reports?.find((element) => element.name === viewName);
    if (report) {
      GetLocation();
      setUrl(embeber(report.secrey_key, parseInt(report.remote_id, 10)));
      setTimeout(() => {
        centrarComponente();
      }, 100);
    } else {
      // Eliminar todos los parámetros de la URL
      url.search = "";

      // Reemplazar la URL en el navegador sin recargar la página
      window.history.replaceState({}, "", url);
    }
  };
  return (
    <ThemeProvider theme={MuiTheme}>
      <div
        className="users-table-container"
        style={{ padding: "1rem", position: "relative" }}
      >
        {currentView != null && (
          <Box sx={{ position: "absolute", top: 10, left: 10 }}>
            <Button
              variant="contained"
              sx={{ width: "3rem", height: "2.5rem", minWidth: "0" }}
              onClick={() => {
                // Crear una instancia del objeto URL con la URL actual
                const url = new URL(window.location);

                // Eliminar todos los parámetros de la URL
                url.search = "";

                // Reemplazar la URL en el navegador sin recargar la página
                window.history.replaceState({}, "", url);
                GetLocation();
              }}
            >
              <ArrowBack></ArrowBack>
            </Button>
          </Box>
        )}
        <Box
          sx={{
            background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
            padding: "10px",
            borderRadius: "15px",
            color: "white",
            height: "80px",
            display: "flex",
            alignItems: "center",
            width: "90%",
            position: "relative",
            bottom: "25px",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: " 700",
              letterSpacing: " 1px",
            }}
          >
            Dashboard Tmotos
          </Typography>
        </Box>
        {/* <div className="report">{getIframe()}</div> */}
        {!currentView ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "70vh",
              gap: "1.2rem",
              flexWrap: "wrap",
              alignContent: "center",
            }}
          >
            <Box
              className="dashboard-item-redirect"
              onClick={() => {
                handleChangeView("Registros Ventas TMotos");
              }}
            >
              Ventas
            </Box>
            <Box
              className="dashboard-item-redirect"
              onClick={() => {
                handleChangeView("Ventas por Dia");
              }}
            >
              Graficos de venta por dia
            </Box>
            <Box
              className="dashboard-item-redirect"
              onClick={() => {
                handleChangeView("Ventas por Mes");
              }}
            >
              Graficos de venta por mes
            </Box>
            <Box
              className="dashboard-item-redirect"
              onClick={() => {
                handleChangeView("Error Triunfo Motos");
              }}
            >
              Error de Datos
            </Box>
          </Box>
        ) : (
          <>
            <div className="report" ref={miRef}>
              {
                <iframe
                  src={url}
                  className="iframe-report"
                  frameBorder="0"
                  width="100%"
                  height="100%"
                  allowtransparency="true"
                ></iframe>
              }
            </div>
          </>
        )}
      </div>
    </ThemeProvider>
  );
};

export default TmotosDashboard;
