import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "../styles/MuiDataTableStyle";
import {
  createPlan,
  getPlans,
} from "../services/plansService";
import { useStateAuthValue } from "../context/AuthState";
import { useToast } from "../context/Toast/ToastProvider";
import { showToast, ToastType } from "../utils/ToastUtil";
import { LoadingModal } from "../components/Modals/LoadingModal";
import { SeePlanDetailsModal } from "../components/Modals/SeePlanDetailsModal";
import { Form, Formik } from "formik";
import { FormikField, SelectObjectField } from "../components/FormFields";
import * as Yup from "yup";

import { useNavigate } from "react-router-dom";
import { FidgetSpinner } from "react-loader-spinner";
import { Circle } from "@mui/icons-material";
import { BaseModal } from "../components/Modals/BaseModal";

const CreatePlan = ({ open, setOpen, getPlansData }) => {
  const [{ userToken }] = useStateAuthValue();
  const toastDispatch = useToast();
  const navigate = useNavigate();

  const displayError = (msg) =>
    showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);

  const [plan, setPlan] = useState({
    planName: "",
    consultationsPerMonth: "",
    familyMembers: "",
    price: "",
    priceMember: "",
  });
  const FormScheme = Yup.object().shape({
    planName: Yup.string().required("No debe quedar vacío"),
    consultationsPerMonth: Yup.string().required("No debe quedar vacío"),
    familyMembers: Yup.string().required("No debe quedar vacío"),
    price: Yup.string().required("No debe quedar vacío"),
    priceMember: Yup.string().required("No debe quedar vacío"),
  });

  const showError = (msgError) => {
    showToast(toastDispatch, msgError, ToastType.ERROR, ToastType.ERROR);
  };
  const formikSetValues = useRef(null);

  const handleCreatePlan = (values) => {
    createPlan(userToken, values, displayError).then((e) => {
      if (e != null) {
        showToast(
          toastDispatch,
          "Se creo con exito",
          ToastType.SUCCESS,
          ToastType.SUCCESS
        );
      }
    });
  };

  return (
    <BaseModal open={open} setOpen={setOpen}>
      <h3>Crear nuevo plan</h3>
      <Formik
        onSubmit={(values) => {
          handleCreatePlan(values);
          setOpen(false);
          getPlansData();
        }}
        initialValues={plan}
        validationSchema={FormScheme}
      >
        {({ isValid, values, setValues, setFieldValue, errors }) => {
          formikSetValues.current = setValues;
          return (
            <Form>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2,1fr)",
                  gridGap: "25px",
                }}
              >
                <FormikField name="planName" label="Nombre de plan" />
                <FormikField name="consultationsPerMonth" label="Consultas por mes" />
                <FormikField name="familyMembers" label="Cantidad de miembros" />
                <FormikField name="price" label="Precio" />
                <FormikField name="priceMember" label="Precio por miembro adicional" />
                <></>
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  disabled={Object.keys(errors).length != 0}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#002c54",
                    margin: "30px 10px 0 0",
                    borderRadius: "20px",
                    texttransform: "capitalize",
                  }}
                >
                  Guardar
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </BaseModal>
  );
};

export default CreatePlan;
