import React from "react";
import { Toolbar } from "@material-ui/core";
import { InputLabel } from "@mui/material";
import { Button } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DatePickerField } from "../FormFields";

const CustomToolbar = ({ filter, setFilter, lookForFilter, setFilterData }) => {
  const handleFilterChange = ({column, newValue}) => {
    setFilter((pastValue) => {
        return pastValue.map((v) => v.label === column.label && newValue !== "" ? { ...v, filterBy: newValue } : v);
      });

    setFilterData((pastValue) => {
      const entries = Object.entries(pastValue);

      // Find the specific key and update its value
      const updatedEntries = entries.map(([key, value]) => {
        if (key === column.column) {
          return [key, newValue];
        } else {
            return [key, value];
        }
      });
      // Convert array back to object
      return Object.fromEntries(updatedEntries);
    });

  };

  return (
    <Toolbar>
      <div className="patient-toolbar">
        <div style={{ display: "flex", gap: "31px" }}>
          {filter?.map((f) => {
            return f.objective !== "select" ? (
              <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    flexBasis: "calc(29%)"
                  }}
                >
                  <InputLabel>{f.label}</InputLabel>
                  <DatePickerField 
                  value={f.filterBy}
                  slotProps={{ textField: { variant: "standard",disabled: true } }}
                  onChange={(value) => {  
                    handleFilterChange({column:f, newValue:value});
                  }}
                  />
                </div>
            ) : (
              <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "200px",
                    flexBasis: "calc(29%)"
                  }}
                >
                  <InputLabel>{f.label}</InputLabel>
                  <FormControl variant="standard" sx={{ width: "100%" }}>
                    <Select
                      labelId="select-label"
                      value={f.filterBy}
                      multiple
                      onChange={(e) => {
                        handleFilterChange({column: f, newValue: e.target.value});
                      }}
                      sx={{ textAlign: "left" }}
                    >
                      {f?.options.map((op) => (
                        <MenuItem value={op.value}> {op.label} </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
            );
          })}
        </div>
        <div>
          <Button
            type="submit"
            onClick={lookForFilter}
            fullWidth
            variant="contained"
            color="primary"
          >
            Aplicar
          </Button>
        </div>
      </div>
    </Toolbar>
  );
};

export default CustomToolbar;
