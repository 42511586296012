/* eslint-disable no-empty-pattern */
import { memo, useEffect, useState } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, Typography, ListItemText } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useStateAuthValue } from "../context/AuthState";
import { setSidebarItems } from "../utils/sibebarPaths";
import Logo from "../assets/images/logo.png";
import Navbar from "./Navbar";
import pkg from "../../package.json";
import { useToast } from "../context/Toast/ToastProvider";
import { showToast, ToastType } from "../utils/ToastUtil";
import { useStateReportValue } from "../context/Reports";

const Item = memo(
  ({
    title,
    path,
    icon,
    selected,
    setSelected,
    action,
    subItems,
    setIsSidebarOpen,
  }) => {
    const [{}, dispatch] = useStateAuthValue();

    let size = subItems ? Object.keys(subItems).length : 0;
    const navigate = useNavigate();
    return size !== 0 ? (
      <SubMenu
        label={title}
        key={title}
        rootStyles={{ fontSize: "1rem !important" }}
        active={selected === title}
        className="menu-item"
        onClick={() => {
          setSelected(title);
          if (action === "LOG_OUT") {
            dispatch({
              type: "LOG_OUT",
            });
            localStorage.removeItem("token");
          }
          if (!subItems?.length) {
            navigate(path);
            window.scrollTo({
              top: 0,
              behavior: "smooth", // para un desplazamiento suave
            });
          }
        }}
        icon={icon}
      >
        {subItems?.map((subItem) => (
          <Box
            key={subItem.title}
            sx={{
              "& .MuiTypography-root ": {
                fontSize: "13px",
              },
              "& .ps-menu-button ": {
                marginRight: "5px",
              },
              "& .ps-menu-button:hover ": {
                background: `#002c54 !important`,
              },

              "& .ps-menu-button::after ": {
                content: '""',
                position: "absolute",
                width: "100%",
                transform: "scaleX(0)",
                height: "2px",
                bottom: 0,
                left: 0,
                backgroundColor: "#0087ca",
                transformOrigin: "bottom right",
                transition: "transform 0.25s ease-out",
              },

              "& .ps-menu-button:hover::after ": {
                transform: "scaleX(1)",
                transformOrigin: "bottom left",
              },

              "& .ps-menu-button:active ": {
                background: `#002c54 !important`,
              },
            }}
          >
            <MenuItem>
              <ListItemText
                sx={{
                  textAlign: "end",
                }}
                onClick={() => {
                  if (subItem.path.includes("?")) {
                    const chars = subItem.path.split("?");
                    navigate({
                      pathname: chars[0],
                      search: `?${createSearchParams({
                        param: chars[1],
                      })}`,
                    });
                  } else {
                    navigate(subItem.path);
                  }
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth", // para un desplazamiento suave
                  });
                  setIsSidebarOpen(false);
                }}
              >
                {subItem.title}
              </ListItemText>
            </MenuItem>
          </Box>
        ))}
      </SubMenu>
    ) : (
      <Box
        sx={{
          "& .ps-menu-label": {
            overflow: "inherit!important",
          },
        }}
      >
        <MenuItem
          key={title}
          active={selected === title}
          className="menu-item"
          onClick={() => {
            setSelected(title);
            navigate(path);
            setIsSidebarOpen(false);
            window.scrollTo({
              top: 0,
              behavior: "smooth", // para un desplazamiento suave
            });
          }}
          icon={icon}
        >
          <Typography>{title}</Typography>
        </MenuItem>
      </Box>
    );
  }
);

const SidebarLayout = ({ children }) => {
  const [{ userToken, userRole }] = useStateAuthValue();
  const [selected, setSelected] = useState("Dashboard");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [sidebarClassName, setSidebarClassName] = useState("sidebar");
  const toastDispatch = useToast();
  const [items, setItems] = useState([]);
  const displayError = (msg) =>
    showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);
  const [{}, dispatch] = useStateReportValue();

  useEffect(() => {
    if (userToken) {
      const getItems = async () => {
        const items = await setSidebarItems(
          userRole,
          userToken,
          displayError,
          dispatch
        );
        setItems(items);
      };
      getItems();
    }
  }, [userToken]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1300) {
        setSidebarClassName("sidebar sidebar-is-closed");
      } else {
        setSidebarClassName("sidebar sidebar-is-open");
      }
    }

    if (userToken) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [userToken]);

  return (
    <div className="layout">
      <Box
        sx={{
          "& .ps-menu-button ": {
            borderRadius: "10px",
            margin: "5px",
          },
          "& .ps-menu-button:hover ": {
            background: `#6d6d6d !important`,
          },
          "& .ps-menu-button:active ": {
            background: `linear-gradient(195deg, #49a3f1, #1A73E8) !important`,
          },
          "& .ps-menu-root": {
            minHeight: "700px",
          },
        }}
      >
        <Sidebar
          className={
            isSidebarOpen ? "sidebar  sidebar-is-open" : sidebarClassName
          }
        >
          <Menu>
            <Box mb="25px">
              <Box
                sx={{
                  position: "absolute",
                  left: "81%",
                  top: "1%",
                }}
              >
                <ClearIcon
                  onClick={() => {
                    setIsSidebarOpen(false);
                  }}
                  className="navbar-open-sidebar"
                />
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ margin: "20px" }}
              >
                <img
                  alt="brand"
                  width="100px"
                  height="100px"
                  src={Logo}
                  style={{ cursor: "pointer", height: "65px" }}
                />
              </Box>
            </Box>
            <hr className="faded-out-hr" />
            {items?.map((item) => {
              return (
                <Item
                  key={item.title}
                  title={item.title}
                  path={item.path}
                  icon={item.icon}
                  action={item?.action}
                  subItems={item.subItems}
                  selected={selected}
                  setSelected={setSelected}
                  setIsSidebarOpen={setIsSidebarOpen}
                />
              );
            })}
          </Menu>
        </Sidebar>
      </Box>
      <div className="container">
        <Navbar
          setIsSidebarOpen={setIsSidebarOpen}
          isSidebarOpen={isSidebarOpen}
        />
        <div>{children}</div>
        {pkg && (
          <div style={{ float: "right", marginTop: "35px" }}>
            <p
              style={{
                fontWeight: "700",
                fontSize: "11px",
              }}
            >
              Versión {pkg?.version}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SidebarLayout;
