import * as React from "react";
import Button from "@mui/material/Button";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { FormikField, SelectObjectField, DatePickerField } from "../FormFields";
import { convertDateFormat } from "../../utils/dateForm";
import { Box, Typography,Slider,Stack,Input } from "@mui/material";


import { BaseModal } from "./BaseModal";
import { useState } from "react";
import { useToast } from "../../context/Toast/ToastProvider";
import { ToastType, showToast } from "../../utils/ToastUtil";
import { useEffect } from "react";
import { useStateAuthValue } from "../../context/AuthState";
import { useRef } from "react";
import { getAllFinancers } from "../../services/financersService";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

export const SeeAgreementDetailsModal = ({
  open,
  setOpen,
  handleClose,
  agreement,
}) => {
  const FormScheme = Yup.object().shape({
    description: Yup.string().required("No debe quedar vacío"),
    //number: Yup.string().required("No debe quedar vacío"),
    status: Yup.string().required("No debe quedar vacío"),
    startDate: Yup.string().required("No debe quedar vacío"),
    endDate: Yup.string().required("No debe quedar vacío"),
    bonus: Yup.number()
      .min(0, "La bonoficacion debe ser mayor o igual a 0")
      .max(100, "La bonoficacion debe ser menor o igual a 100")
      .required("No debe quedar vacío"),
    financerId: Yup.string().required("No debe quedar vacío"),
  });

  const [currentFinancer, setCurrentFinancer] = useState({});

  const [financersList, setFinancersList] = useState([]);
  const toastDispatch = useToast();
  const showError = (msgError) => {
    showToast(toastDispatch, msgError, ToastType.ERROR, ToastType.ERROR);
  };
  const [{ userToken }] = useStateAuthValue();
  const formikSetValues = useRef(null);

  const fetchFinancers = async () => {
    const response = await getAllFinancers(userToken, showError);

    let _financerList = [];
    response?.map((financer) => {
      _financerList = [
        ..._financerList,
        {
          name: financer.financerName,
          id: financer.id,
        },
      ];
    });
    setFinancersList(_financerList);
  };

  useEffect(() => {
    fetchFinancers();
  }, []);

  return (
    <div>
      <BaseModal open={open} setOpen={setOpen}>
        <Formik
          initialValues={{
            description: agreement?.description,
            number: agreement?.number,
            status: agreement?.status,
            startDate: dayjs(new Date(agreement?.effectiveDate)),
            endDate: dayjs(new Date(agreement?.effectiveDate)),
            bonus: agreement?.bonus,
            financerId: agreement?.financerId,
          }}
          validationSchema={FormScheme}
        >
          {({ isValid, values, setValues, setFieldValue, errors }) => {
            formikSetValues.current = setValues;
            return (
              <Form>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2,1fr)",
                    gridGap: "25px",
                  }}
                >
                  <SelectObjectField
                    label="Empresa financiadora"
                    setValue={(e) => {
                      setValues({ ...values, financerId: e.id });
                      setCurrentFinancer(e);
                      agreement.financer.financerName = e.name;
                    }}
                    value={{
                      name: agreement?.financer?.financerName,
                      id: agreement?.financerId,
                    }}
                    data={financersList}
                    className="field"
                    errorMsg={errors.financerId}
                    isError={errors.financerId}
                  />
                  <FormikField name="description" label="Descripción" />
                  <FormikField
                    disabled={true}
                    name="number"
                    label="Nro.Convenio"
                  />
                  <FormControl>
                    <DatePickerField
                      label="Fecha Inicio"
                      value={agreement?.startDate}
                      defaultValue={dayjs(new Date(agreement.startDate))}
                      slotProps={{
                        textField: { variant: "standard", disabled: true },
                      }}
                      onChange={(value) => {
                        setFieldValue(
                          "startDate",
                          value.format("YYYY-MM-DDT00:00:00Z")
                        );
                      }}
                    />
                    <DatePickerField
                      label="Fecha Fin"
                      value={agreement?.endDate}
                      defaultValue={dayjs(new Date(agreement.endDate))}
                      slotProps={{
                        textField: { variant: "standard", disabled: true },
                      }}
                      onChange={(value) => {
                        setFieldValue(
                          "endDate",
                          value.format("YYYY-MM-DDT00:00:00Z")
                        );
                      }}
                    />
                  </FormControl>

                  <FormControl>
                    <InputLabel id="select-label">Estado</InputLabel>
                    <Select
                      labelId="select-label"
                      id="simple-select"
                      label="Estado"
                      value={agreement.status}
                      onChange={(event) => {
                        event.preventDefault();
                        setFieldValue("status", event.target.value);
                      }}
                    >
                      <MenuItem value={"ACTIVE"}>Activo</MenuItem>
                      <MenuItem value={"INACTIVE"}>Inactivo</MenuItem>
                    </Select>
                  </FormControl>
                  <Stack>
                      <Typography id="input-slider">Bonificacion</Typography>
                      <Stack
                        spacing={2}
                        direction="row"
                        sx={{ mb: 1 }}
                        alignItems="center"
                      >
                        <Slider
                          aria-label="Bonus"
                          value={values.bonus}
                          step={10}
                          valueLabelDisplay="auto"
                          min={0}
                          max={100}
                          onChange={(event, newValue) => {
                            setFieldValue("bonus", newValue);

                            console.log(newValue);
                          }}
                        />
                        <Input
                          value={values.bonus}
                          size="small"
                          onChange={(e) => {
                            setFieldValue("bonus", e.target.value);
                          }}
                          style={{ width: "4rem" }}
                          inputProps={{
                            step: 10,
                            min: 0,
                            max: 100,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />
                        %
                      </Stack>
                      <Typography
                        id="input-slider"
                        style={{ color: "#f44336", fontSize: "12px" }}
                      >
                        {errors.bonus}
                      </Typography>
                    </Stack>
                  <></>
                </div>
                <div style={{ display: "flex" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#DA2037",
                      margin: "30px 10px 0 0",
                      borderRadius: "20px",
                      texttransform: "capitalize",
                    }}
                    onClick={() => setOpen(false)}
                  >
                    Cancelar
                  </Button>
                  <Button
                    disabled={Object.keys(errors).length != 0}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#002c54",
                      margin: "30px 10px 0 0",
                      borderRadius: "20px",
                      texttransform: "capitalize",
                    }}
                    onClick={() => {
                      handleClose(values);
                    }}
                  >
                    Guardar
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </BaseModal>
    </div>
  );
};
