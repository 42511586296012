const capitalize = ([first,...rest]) => first.toUpperCase() + rest.join('').toLowerCase();

export const showToast = (dispatch, message, toastType, title) => {
   dispatch({
      title: title ? capitalize(title) : '',
      toastType: toastType,
      message: message,
   });
};

export const ToastType = {
   ERROR: 'ERROR',
   INFO: 'INFO',
   SUCCESS: 'SUCCESS',
   WARNING: 'WARNING',
};

export function checkStatus(status) {
   switch (status) {
       case 'CONSULTATION_FINISHED':
           return "CONSULTA FINALIZADA";
       default:
           return "DESCONOCIDO";
   }
}

