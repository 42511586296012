import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const SendLink = ({clientValues, submit}) => {

    return(
        <div className="review-data center-flex">
            <h3>Datos ingresados</h3>
            <hr></hr>
            <div className="data-container">
                <div>
                <p>Nombre: {clientValues.first_name}</p>
                <p>Apellido: {clientValues.last_name}</p>
                <p>DNI: {clientValues.id_number}</p>
                <p>Teléfono: {clientValues.phone_number}</p>
                <p>Correo: {clientValues.phone_number}</p>
                <p>Plan Mediline: {clientValues?.plan?.name}</p>
                <p>Importe: ${clientValues?.plan?.price}</p>
                </div>
                <div className='icon-container' onClick={submit}>
                    <h5>Enviar link de pago</h5>
                    <WhatsAppIcon />
                </div>

            </div>
        </div>
    )
};

export default SendLink;