import { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  IconButton,
  InputAdornment,
  Icon,
  Button,
  Paper,
} from "@material-ui/core";
import FormikField from "../components/FormFields/FormikField";
import { useStateAuthValue } from "../context/AuthState";
import { useNavigate } from "react-router-dom";
import { login } from "../services/authService";
import { useToast } from "../context/Toast/ToastProvider";
import { showToast, ToastType } from "../utils/ToastUtil";
import { loginStyles } from "../styles/makeStyles.js"
import Logo from "../assets/images/logo-white.png";

const Login = () => {
  const classes = loginStyles();
  const navigate = useNavigate();
  const [{}, dispatch] = useStateAuthValue();
  const toastDispatch = useToast();
  const [hidePassword, seHidePassword] = useState(true);

  const LoginScheme = Yup.object().shape({
    username: Yup.string()
      .email("Debe ingresar un email válido")
      .required("Email Requerido"),
    password: Yup.string()
      .min(6, " Debe tener al menos 6 caracteres")
      .required("Debe ingresar una contraseña"),
  });

  const handleClickShowPassword = () => seHidePassword(!hidePassword);

  const IconVisible = (icon) => {
    return <Icon className={classes.visibility}> {icon}</Icon>;
  };

  return (
    <div className="login">
      <div>
        <Formik
          initialValues={{
            username: "",
            password: "",
          }}
          onSubmit={async (values) => {
            const displayError = (msg) => showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);
            const response = await login(values, displayError);
            if (response) {
              localStorage.setItem("token", response.id_token);
              localStorage.setItem("role", response.role);
              dispatch({
                type: "LOG_IN",
                userData: response,
                userToken: response.id_token,
                userRole: response.role,
              });
              navigate("/");
            }
          }}
          validationSchema={LoginScheme}
        >
          {({ dirty, isValid }) => {
            return (
              <Form>
                <Paper elevation={3} className="login__container">
                  <img
                    alt="brand"
                    width="40%"
                    height="40%"
                    className="center-img"
                    src={Logo}
                  />
                  <FormikField name="username" label="Email" />
                  <FormikField
                    name="password"
                    label="Contraseña"
                    type={hidePassword ? "password" : "text"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            className={classes.iconButton}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {hidePassword
                              ? IconVisible("visibility_off")
                              : IconVisible("visibility")}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    disabled={!(isValid && dirty)}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    endIcon={<Icon>chevron_right</Icon>}
                  >
                    Ingresar
                  </Button>
                </Paper>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Login;
