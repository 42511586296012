import { useEffect, useState } from "react";
import { getReportsVoyEnBus } from "../services/reportsService";
import { useStateAuthValue } from "../context/AuthState";
import { ToastType, showToast } from "../utils/ToastUtil";
import { useToast } from "../context/Toast/ToastProvider";
import encode from "jwt-encode";
import { getFormattedCurrentDate } from "../utils/dateNow";
const VoyEnBusReport = () => {
  const [{userToken}] = useStateAuthValue();
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [name, setName] = useState(null);
  const [url, setUrl] = useState(null);
  const toastDispatch = useToast();
  const displayError = (msg) => showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);

  const getReportsData = async () => {
    const response = await getReportsVoyEnBus(userToken, displayError);
    setReports(response);
    setLoading(true);
  }

  const embeber = (token, dashboard, from, to) => {
    if (!token || !dashboard) return;
    const payload = {
      resource: { dashboard: dashboard },
      params: {},
      exp: Math.round(Date.now() / 1000) + 600, // 10 minutos de expiración
    };
    const tokenJWT = encode(payload, token);
    const url =
      process.env.REACT_APP_API_URL +
      `/embed/dashboard/${tokenJWT}#bordered=true&titled=true&fecha_desde=${from}&fecha_hasta=${to}`;
    return url;
  };
  useEffect(() => {
    if (userToken) {
      getReportsData();
      if (reports) {
        setName(reports[0]?.name);
        setUrl(embeber(reports[0]?.secrey_key, parseInt(reports[0]?.remote_id, 10), getFormattedCurrentDate(0, '01'), getFormattedCurrentDate(0, null)));
      }
    }
  }, [loading, userToken]);


  const getIframe = () => {
    return (
        <iframe
            title={name}
            src={url}
            className="iframe-report"
            frameBorder="0"
            width="100%"
            height="100%"
            allowtransparency="true">
        </iframe>
    );
  }

  return (
    <div className="report">
        {getIframe()}
    </div>
  );
};

export default VoyEnBusReport;