import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box } from "@mui/material";

const DatePickerField = (props) => {
  const { label, requiredField, value, onChange, ...rest } = props;
  const labelField = requiredField ? `${label}*` : label;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          "& .MuiFormControl-root .MuiInputBase-root .MuiInputAdornment-root .MuiButtonBase-root:focus":
            {
              outline: "none",
            },
        }}
      >
        <DesktopDatePicker
          slotProps={{
            textField: {
              variant: "standard",
            },
          }}
          format="DD-MM-YYYY"
          label={labelField}
          onChange={onChange}
          {...rest}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default DatePickerField;
