import axios from 'axios';

const prepaidHealths = `${process.env.REACT_APP_BASE_URL}/services/parametrics/api/prepaid-healths/`;
const prepaidHealthsCategories = `${process.env.REACT_APP_BASE_URL}/services/parametrics/api/prepaid-health-categories/`;

export const getSocialWork = async (token, displayError = () => {}) => {
    let url = `${prepaidHealths}`
    return await axios.get(url,{ headers: {authorization: `Bearer ${token}`}} )
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        if(token) displayError("getSocialWork error: " + error.message)
    });
}

export const getSocialWorkCategory = async (token, displayError = () => {}, id) => {
    let url = `${prepaidHealthsCategories}prepaid/${id}`
    return await axios.get(url,{ headers: {authorization: `Bearer ${token}`}} )
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        if(token) displayError("getSocialWork error: " + error.message)
    });
}

export const postSocialWork = async (token, displayError = () => {}, json) => {
    let url = `${prepaidHealths}`
    return await axios.post(url,json,{ headers: {authorization: `Bearer ${token}`}})
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        if (error?.response?.data?.message){
            displayError(error?.response?.data?.message);
        }else{
            if(token) displayError("postSocialWork error: " + error.message);
        }
    });
}

export const postSocialWorkCategory = async (token, displayError = () => {}, json) => {
    let url = `${prepaidHealthsCategories}`
    return await axios.post(url,json,{ headers: {authorization: `Bearer ${token}`}})
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        if (error?.response?.data?.message){
            displayError(error?.response?.data?.message);
        }else{
            if(token) displayError("postSocialWorkCategory error: " + error.message);
        }
    });
}

export const putSocialWork = async (token, displayError = () => {}, json, id) => {
    let url = `${prepaidHealths}${id}`
    return await axios.put(url,json,{ headers: {authorization: `Bearer ${token}`}})
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        if (error?.response?.data?.message){
            displayError(error?.response?.data?.message);
        }else{
            if(token) displayError("postSocialWork error: " + error.message);
        }
    });
}

export const putSocialWorkCategory = async (token, displayError = () => {}, json, id) => {
    let url = `${prepaidHealthsCategories}/${id}`
    return await axios.put(url,json,{ headers: {authorization: `Bearer ${token}`}})
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        if (error?.response?.data?.message){
            displayError(error?.response?.data?.message);
        }else{
            if(token) displayError("postSocialWorkCategory error: " + error.message);
        }
    });
}