import * as React from "react";
import Button from "@mui/material/Button";
import { Formik, Field, FieldArray, Form } from "formik";
import * as Yup from "yup";
import { FormikField } from "../FormFields";
import { BaseModal } from "./BaseModal";
import { IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

export const SocialWorkDetailsModal = ({ open, setOpen, handleClose, socialWork }) => {
  const FormScheme = Yup.object().shape({
    id: Yup.string().notRequired(),
    name: Yup.string().required("No debe quedar vacío"),
    plan: Yup.array().of(Yup.string()), // Puedes ajustar la validación según tus necesidades
  });

  const plan = (values) => {
    return (
      <FieldArray name="plan">
        {({ push, remove }) => (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
            {values.plan.map((_, index) => (
              <div key={index} style={{ display: "flex", marginBottom: "8px" }}>
                <Field
                  as={FormikField}
                  name={`plan[${index}].name`}
                  label={`Plan ${index + 1}`}
                />
                {(values.plan.length !== 1 && values.plan[index].id === null) ? (
                  <IconButton onClick={() => remove(index)}>
                    <ClearIcon />
                  </IconButton>
                ):(
                  <IconButton>
                    <DoDisturbIcon />
                  </IconButton>
                )}
              </div>
            ))}
            <IconButton onClick={() => push({ id: null, name: "" })}>
              <AddIcon />
            </IconButton>
          </div>
        )}
      </FieldArray>
    );
  };

  return (
    <div>
      <BaseModal open={open} setOpen={setOpen}>
        <Formik
          initialValues={{
            id: socialWork.id || null,
            name: socialWork.name || "",
            plan: socialWork.plan || [{id: null, name: ''}], // Inicializamos el array con un elemento vacío
          }}
          validationSchema={FormScheme}
        >
          {({ isValid, values }) => (
            <Form>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2,1fr)",
                  gridGap: "25px",
                }}
              >
                <FormikField name="name" label="Obra Social" />
                {plan(values)}
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#DA2037",
                    margin: "30px 10px 0 0",
                    borderRadius: "20px",
                    textTransform: "capitalize",
                  }}
                  onClick={() => setOpen(false)}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#002c54",
                    margin: "30px 10px 0 0",
                    borderRadius: "20px",
                    textTransform: "capitalize",
                  }}
                  onClick={() => handleClose(values)}
                >
                  Guardar
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </BaseModal>
    </div>
  );
};