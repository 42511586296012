import { Box, Fade, Popper } from "@mui/material";
import React, { useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export const NewUserTollbar = ({ onClickFunc }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const canBeOpen = open && anchorEl;
  const id = canBeOpen ? "transition-popper" : undefined;
  return (
    <span>
      <button
        aria-describedby={id}
        onMouseEnter={(event) => {
          setAnchorEl(event.currentTarget);
          setOpen(true);
        }}
        onMouseLeave={(event) => {
          setAnchorEl(null);
          setOpen(false);
        }}
        onClick={onClickFunc}
        className="MuiButtonBase-root MuiIconButton-root tss-10rusft-MUIDataTableToolbar-icon MuiIconButton-sizeMedium css-78trlr-MuiButtonBase-root-MuiIconButton-root"
      >
        <AddCircleIcon></AddCircleIcon>
      </button>
      <Popper
        className="base-Popper-root MuiTooltip-popper MuiTooltip-popperInteractive css-yem012-MuiPopper-root-MuiTooltip-popper"
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box
              className="MuiT001tip-t001tip MuiT001tip-t001tipP1acementBottom css-ja5taz-MuiTooltip-tooltip"
              sx={{ border: 1, p: 1, bgcolor: "background.paper" }}
            >
              Crear nuevo usuario bonificado
            </Box>
          </Fade>
        )}
      </Popper>
    </span>
  );
};
