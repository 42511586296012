import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { Box, Button, Typography,Slider,Stack,Input } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "../styles/MuiDataTableStyle";
import {
  createAgreement,
  getAgreements,
  getLastAgreementNumber,
} from "../services/agreementsService";
import { getAllFinancers } from "../services/financersService";
import { useStateAuthValue } from "../context/AuthState";
import { useToast } from "../context/Toast/ToastProvider";
import { showToast, ToastType } from "../utils/ToastUtil";
import { LoadingModal } from "../components/Modals/LoadingModal";
import { SeeAgreementDetailsModal } from "../components/Modals/SeeAgreementDetailsModal";
import { Form, Formik } from "formik";
import {
  FormikField,
  SelectObjectField,
  DatePickerField,
} from "../components/FormFields";
import * as Yup from "yup";

import { useNavigate } from "react-router-dom";
import { FidgetSpinner } from "react-loader-spinner";
import { Circle } from "@mui/icons-material";
import { BaseModal } from "../components/Modals/BaseModal";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

const CreateAgreement = ({ open, setOpen, getAgreementsData }) => {
  const [{ userToken }] = useStateAuthValue();
  const toastDispatch = useToast();
  const navigate = useNavigate();

  const displayError = (msg) =>
    showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);

  const [agreement, setAgreement] = useState({
    description: "",
    number: null,
    status: null,
    startDate: "",
    endDate: "",
    bonus: 0,
    financerId: null,
  });
  const FormScheme = Yup.object().shape({
    description: Yup.string().required("No debe quedar vacío"),
    //number: Yup.string().required("No debe quedar vacío"),
    status: Yup.string().required("No debe quedar vacío"),
    startDate: Yup.string().required("No debe quedar vacío"),
    endDate: Yup.string().required("No debe quedar vacío"),
    bonus: Yup.number()
      .min(0, "La bonoficacion debe ser mayor o igual a 0")
      .max(100, "La bonoficacion debe ser menor o igual a 100")
      .required("No debe quedar vacío"),
    financerId: Yup.string().required("No debe quedar vacío"),
  });
  const [financersList, setFinancersList] = useState([]);

  const [currentFinancer, setCurrentFinancer] = useState({});

  const showError = (msgError) => {
    showToast(toastDispatch, msgError, ToastType.ERROR, ToastType.ERROR);
  };
  const formikSetValues = useRef(null);

  const fetchFinancers = async () => {
    const response = await getAllFinancers(userToken, showError);

    let _financerList = [];
    response?.map((financer) => {
      _financerList = [
        ..._financerList,
        {
          name: financer.financerName,
          id: financer.id,
        },
      ];
    });
    setFinancersList(_financerList);
  };
  const fetchGetLastAgreementNumber = async () => {
    const response = await getLastAgreementNumber(userToken, showError);
    setAgreement({ ...agreement, number: response.number });
  };

  useEffect(() => {
    fetchFinancers();
    fetchGetLastAgreementNumber();
  }, []);

  const handleCreateAgreement = (values) => {
    values.financerId = currentFinancer.id;
    createAgreement(userToken, values, displayError).then((e) => {
      if (e != null) {
        showToast(
          toastDispatch,
          "Se creo con exito",
          ToastType.SUCCESS,
          ToastType.SUCCESS
        );
      }
    });
  };
  return (
    <>
      {agreement.number == null ? (
        <></>
      ) : (
        <BaseModal open={open} setOpen={setOpen}>
          <h3>Crear nuevo convenio</h3>
          <Formik
            onSubmit={(values) => {
              handleCreateAgreement(values);
              setOpen(false);
              getAgreementsData();
            }}
            initialValues={agreement}
            validationSchema={FormScheme}
          >
            {({ isValid, values, setValues, setFieldValue, errors }) => {
              formikSetValues.current = setValues;
              return (
                <Form>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2,1fr)",
                      gridGap: "25px",
                    }}
                  >
                    <SelectObjectField
                      label="Empresa financiadora"
                      setValue={(e) => {
                        setValues({ ...values, financerId: e.id });
                        setCurrentFinancer(e);
                      }}
                      value={currentFinancer}
                      data={financersList || []}
                      className="field"
                      errorMsg={errors.financerId}
                      isError={errors.financerId}
                    />
                    <FormikField name="description" label="Descripción" />
                    <FormikField disabled={true} name="number" label="Nro.Convenio" />
                    {/* <FormikField name="effectiveDate" label="Fecha de vigencia" /> */}
                    {/* <FormikField name="status" label="Fecha de vigencia" /> */}
                    <FormControl>
                      <DatePickerField
                        label="Fecha Inicio"
                        value={agreement?.startDate}
                        slotProps={{
                          textField: { variant: "standard", disabled: true },
                        }}
                        onChange={(value) => {
                          setFieldValue(
                            "startDate",
                            value.format("YYYY-MM-DDT00:00:00Z")
                          );
                        }}
                      />
                      <DatePickerField
                        label="Fecha Fin"
                        value={agreement?.endDate}
                        slotProps={{
                          textField: { variant: "standard", disabled: true },
                        }}
                        onChange={(value) => {
                          setFieldValue(
                            "endDate",
                            value.format("YYYY-MM-DDT00:00:00Z")
                          );
                        }}
                      />
                    </FormControl>
                    <FormControl>
                      <InputLabel id="select-label">Estado</InputLabel>
                      <Select
                        labelId="select-label"
                        id="simple-select"
                        label="Estado"
                        onChange={(event) => {
                          event.preventDefault();
                          setFieldValue("status", event.target.value);
                        }}
                      >
                        <MenuItem value={"ACTIVE"}>Activo</MenuItem>
                        <MenuItem value={"INACTIVE"}>Inactivo</MenuItem>
                      </Select>
                    </FormControl>
                    <Stack>
                      <Typography id="input-slider">Bonificacion</Typography>
                      <Stack
                        spacing={2}
                        direction="row"
                        sx={{ mb: 1 }}
                        alignItems="center"
                      >
                        <Slider
                          aria-label="Bonus"
                          value={values.bonus}
                          step={10}
                          valueLabelDisplay="auto"
                          min={0}
                          max={100}
                          onChange={(event, newValue) => {
                            setFieldValue("bonus", newValue);

                            console.log(newValue);
                          }}
                        />
                        <Input
                          value={values.bonus}
                          size="small"
                          onChange={(e) => {
                            setFieldValue("bonus", e.target.value);
                          }}
                          style={{ width: "4rem" }}
                          inputProps={{
                            step: 10,
                            min: 0,
                            max: 100,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />
                        %
                      </Stack>
                      <Typography
                        id="input-slider"
                        style={{ color: "#f44336", fontSize: "12px" }}
                      >
                        {errors.bonus}
                      </Typography>
                    </Stack>
                    <></>
                  </div>
                  <div style={{ display: "flex" }}>
                    <Button
                      disabled={Object.keys(errors).length != 0}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      style={{
                        backgroundColor: "#002c54",
                        margin: "30px 10px 0 0",
                        borderRadius: "20px",
                        texttransform: "capitalize",
                      }}
                    >
                      Guardar
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </BaseModal>
      )}
    </>
  );
};

export default CreateAgreement;
