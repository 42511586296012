import { useLocation } from "react-router-dom";
import { useStateReportValue } from "../context/Reports";
import { useState, useEffect } from "react";
import { getFormattedCurrentDate } from "../utils/dateNow";
import encode from "jwt-encode";

const Reports = () => {
  const [{reports}] = useStateReportValue();
  const [name, setName] = useState(null);
  const [url, setUrl] = useState(null);
  let location = useLocation().search.split('?param=')[1];

  const embeber = (token, dashboard, from, to) => {
    const payload = {
      resource: { dashboard: dashboard },
      params: {},
      exp: Math.round(Date.now() / 1000) + 600, // 10 minutos de expiración
    };
    const tokenJWT = encode(payload, token);
    const url = process.env.REACT_APP_API_URL + `/embed/dashboard/${tokenJWT}#bordered=true&titled=true&fecha_desde=${from}&fecha_hasta=${to}`;
    return url
  }

  useEffect(() => {
    if (reports) {
      reports.map((element) => {
        if(element.slug === location){
          setName(element.name);
          if (element.slug === 'ventas_por_dia'){
            setUrl(embeber(element?.secrey_key, parseInt(element?.remote_id, 10), getFormattedCurrentDate(0, '01'), getFormattedCurrentDate(0, null)));
          }else{
            setUrl(embeber(element?.secrey_key, parseInt(element?.remote_id, 10), getFormattedCurrentDate(6, '01'), getFormattedCurrentDate(0, null)));
          }
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, reports]);

  const getIframe = () => {
    return (
        <iframe
            title={name}
            src={url}
            className="iframe-report"
            frameBorder="0"
            width="100%"
            height="100%"
            allowtransparency="true">
        </iframe>
    );
  }

  return (
    <div className="report">
        {getIframe()}
    </div>
  );
};

export default Reports;
