import React from "react";
import { useField } from "formik";
import { TextField } from "@material-ui/core";

const InputField = (props) => {
  const {
    maxLength = 3072,
    readOnly = false,
    extraLabel,
    label,
    placeholder,
    requiredField,
    rows,
    change,
    ...rest
  } = props;
  const [field, meta] = useField(props);
  const { touched, error } = meta;
  const isError = !!(touched && error);
  const labelField = requiredField ? `${label}*` : label;

  const renderHelperText = () => {
    if (isError) {
      return error;
    } else if (extraLabel) {
      return extraLabel;
    }
  };

  return (
    <TextField
      {...field}
      label={labelField}
      multiline={!!rows}
      minRows={rows}
      error={isError}
      placeholder={placeholder}
      helperText={renderHelperText()}
      inputProps={{ maxLength: maxLength, readOnly: readOnly }}
      onChange={(e) => {
        field.onChange(e);
        if (change) {
          change(e);
        }
      }}
      {...rest}
    />
  );
};

export default InputField;
