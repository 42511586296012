import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "../styles/MuiDataTableStyle";
import { MySalesTable } from "../components/mySalesTable";
import { getPatients } from "../services/patientsService";
import { useStateAuthValue } from "../context/AuthState";
import { useToast } from "../context/Toast/ToastProvider";
import { showToast, ToastType } from "../utils/ToastUtil";
import { LoadingModal } from "../components/Modals/LoadingModal";

const MySales = () => {
  const [{ userToken }] = useStateAuthValue();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const toastDispatch = useToast();
  const displayError = (msg) =>
    showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);

  const getPatientsData = async () => {
    setLoading(true);
    const response = await getPatients(userToken, displayError);
    setData(response.patients);
    setLoading(false);
  };
  useEffect(() => {
    getPatientsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  return (
    <ThemeProvider theme={MuiTheme}>
      <div className="users" style={{ maxWidth: "1035px" }}>
        {loading ? (
          <LoadingModal />
        ) : (
          <div className="users-table-container">
            <Box
              sx={{
                background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                padding: "10px",
                borderRadius: "15px",
                color: "white",
                height: "80px",
                display: "flex",
                alignItems: "center",
                width: "90%",
                position: "relative",
                bottom: "25px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: " 700",
                  letterSpacing: " 1px",
                }}
              >
                Mis Ventas
              </Typography>
            </Box>
            <MySalesTable
              data={data}
              getPatientsData={getPatientsData}
              setLoading={setLoading}
            />
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};

export default MySales;
