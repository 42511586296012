import axios from 'axios';

const paymentUrl = `${process.env.REACT_APP_BASE_URL}/services/userprofile/api/patients/backoffice/payment-method`;

export const editPayment = async (token, id, data, displayError = () => {}) => {
    return await axios.put(`${paymentUrl}/${id}`, data,{ headers: {authorization: `Bearer ${token}`}} )
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        if(token) displayError("editPayment error: " + error.message)
    });
}