import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const PieChart = ({
    labels,
    dataconfig
}) => {
    const data = {
        labels: labels,
        datasets: [
          dataconfig,
        ],
      };

  return <Pie data={data} />;
}
