import axios from 'axios';

const prepaidHealthCategoryUrl = `${process.env.REACT_APP_BASE_URL}/services/parametrics/api`;

export const getPrepaidHealthCategoryByPrepaidHealth = async (token, id, displayError = () => {}) => {
    let url = `${prepaidHealthCategoryUrl}/prepaid-health-categories/prepaid/${id}`
    return await axios.get(url,{ headers: {authorization: `Bearer ${token}`}} )
    .then((response) => {
        return response;
    })
    .catch((error) => {
        if(token) displayError("getPrepaidHealthCategoryByPrepaidHealth error: " + error.message)
    });
}

export const getPrepaidHealth = async (token, displayError = () => {}) => {
    let url = `${prepaidHealthCategoryUrl}/prepaid-healths`
    return await axios.get(url,{ headers: {authorization: `Bearer ${token}`}} )
    .then((response) => {
        return response;
    })
    .catch((error) => {
        if(token) displayError("getPrepaidHealth error: " + error.message)
    });
}

