import * as React from "react";
import Button from "@mui/material/Button";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {FormikField, SelectObjectField} from "../FormFields";
import { BaseModal } from "./BaseModal";
import { useState } from "react";
import { useToast } from "../../context/Toast/ToastProvider";
import { ToastType, showToast } from "../../utils/ToastUtil";
import { useEffect } from "react";
import { useStateAuthValue } from "../../context/AuthState";
import { useRef } from "react";
import { getAllAgreements } from "../../services/agreementsService";
import { getAllPlans } from "../../services/plansService";
import { Input } from "@mui/material";

export const SeeFinancerPlanDetailsModal = ({
  open,
  setOpen,
  handleClose,
  financerPlan,
}) => {
  const FormScheme = Yup.object().shape({
    planId: Yup.string().required("No debe quedar vacío"),
    consultationsPerMonth: Yup.string().required("No debe quedar vacío"),
    familyMembers: Yup.string().required("No debe quedar vacío"),
    price: Yup.string().required("No debe quedar vacío"),
    priceMember: Yup.string().required("No debe quedar vacío"),
    financerId: Yup.string().required("No debe quedar vacío"),
    agreementId: Yup.string().required("No debe quedar vacío"),
  });

  const [planList, setPlanList] = useState([]);
  const [agreementsList, setAgreementsList] = useState([]);
  const [allAgreements, setAllAgreements] = useState([]);
  const [allPlans, setAllPlans] = useState([]);

  const toastDispatch = useToast();
  const showError = (msgError) => {showToast(toastDispatch, msgError, ToastType.ERROR, ToastType.ERROR);};
  const [{ userToken }] = useStateAuthValue();
  const formikSetValues = useRef(null);

  const fetchAgreements = async () => {
    const response = await getAllAgreements(userToken, showError);

    let _agreementList = [];
    response?.map((agreement) => {
      _agreementList = [
        ..._agreementList,
        {
          name: agreement.financer.financerName + ' ' + agreement.description,
          id: agreement.id,
        },
      ];
    });

    setAllAgreements(response);
    setAgreementsList(_agreementList);
  };

  const fetchPlans = async () => {
    const response = await getAllPlans(userToken, showError);

    let _planList = [];
    response?.map((plan) => {
      _planList = [
        ..._planList,
        {
          name: plan.planName,
          id: plan.id,
        },
      ];
    });

    setAllPlans(response);
    setPlanList(_planList);
  };

  useEffect(() => {
    fetchAgreements();
    fetchPlans();
  }, []);

  return (
    <div>
      <BaseModal open={open} setOpen={setOpen}>
        <Formik
          initialValues={financerPlan}
          validationSchema={FormScheme}
        >
          {({ isValid, values, setValues, setFieldValue, errors }) => {
            formikSetValues.current = setValues;
            return (
              <Form>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2,1fr)",
                    gridGap: "25px",
                  }}
                >
                <FormikField disabled={true} name={"Empresa"} value={financerPlan?.financer?.financerName} label="Empresa" />
                <SelectObjectField
                  label="Convenio"
                  setValue={(e) => {
                    let _agreement = allAgreements?.filter(a => a.id===e.id);
                    setValues({ ...values, agreementId: e.id, financerId: _agreement!=null && _agreement.length>0 ? _agreement[0].financerId : null});

                    financerPlan.financer.financerName=_agreement[0].financer.financerName;
                    financerPlan.agreement.description=_agreement[0].description;
                  }}
                  value={{
                    name: financerPlan?.agreement?.description,
                    id: financerPlan?.agreementId
                  }}
                  data={agreementsList}
                  className="field"
                  errorMsg={errors.agreementId}
                    isError={errors.agreementId}
                    disabled={true}
                />
                <SelectObjectField
                  label="Plan"
                  setValue={(e) => {
                    let _plan = allPlans?.filter(p => p.id===e.id);
                    _plan = _plan.length>0 ? _plan[0] : [];
                    setValues({ ...values, planId: e.id, planName: e.name, price: _plan.price, priceMember: _plan.priceMember,
                      familyMembers: _plan.familyMembers, consultationsPerMonth: _plan.consultationsPerMonth });
                    financerPlan.planName=e.name;
                  }}
                  value={{
                    name: financerPlan?.planName,
                    id: financerPlan?.planId
                  }}
                  data={planList}
                  className="field"
                  errorMsg={errors.planId}
                  isError={errors.planId}
                />
                <FormikField disabled={true} name="consultationsPerMonth" label="Consultas por mes" />
                <FormikField disabled={true} name="familyMembers" label="Cantidad de miembros" />
                <FormikField disabled={true} name="price" label="Precio" />
                <FormikField disabled={true} name="priceMember" label="Precio por miembro adicional" />
                  <>
              </>
                </div>
                <div style={{ display: "flex"}}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#DA2037",
                    margin: "30px 10px 0 0",
                    borderRadius: "20px",
                    texttransform: "capitalize",
                  }}
                  onClick={() => setOpen(false)}
                >
                  Cancelar
                </Button>
                <Button
                  disabled={Object.keys(errors).length != 0}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#002c54",
                    margin: "30px 10px 0 0",
                    borderRadius: "20px",
                    texttransform: "capitalize",
                  }}
                  onClick={() => {
                    handleClose(values)
                  }}
                >
                  Guardar
                </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </BaseModal>
    </div>
  );
};
