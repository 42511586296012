import * as React from "react";
import Button from "@mui/material/Button";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import {FormikField, SelectObjectField} from "../FormFields";
import { BaseModal } from "./BaseModal";
import { useState } from "react";
import { useToast } from "../../context/Toast/ToastProvider";
import { ToastType, showToast } from "../../utils/ToastUtil";
import { useEffect } from "react";
import { useStateAuthValue } from "../../context/AuthState";
import { useRef } from "react";

export const SeePlanDetailsModal = ({
  open,
  setOpen,
  handleClose,
  plan,
}) => {
  const FormScheme = Yup.object().shape({
    planName: Yup.string().required("No debe quedar vacío"),
    consultationsPerMonth: Yup.string().required("No debe quedar vacío"),
    familyMembers: Yup.string().required("No debe quedar vacío"),
    price: Yup.string().required("No debe quedar vacío"),
    priceMember: Yup.string().required("No debe quedar vacío"),
  });

  const toastDispatch = useToast();
  const showError = (msgError) => {showToast(toastDispatch, msgError, ToastType.ERROR, ToastType.ERROR);};
  const [{ userToken }] = useStateAuthValue();
  const formikSetValues = useRef(null);

  return (
    <div>
      <BaseModal open={open} setOpen={setOpen}>
        <Formik
          initialValues={{
            planName: plan?.planName,
            consultationsPerMonth: plan?.consultationsPerMonth,
            familyMembers: plan?.familyMembers,
            price: plan?.price,
            priceMember: plan?.priceMember,
          }}
          validationSchema={FormScheme}
        >
          {({ isValid, values, setValues, setFieldValue, errors }) => {
            formikSetValues.current = setValues;
            return (
              <Form>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2,1fr)",
                    gridGap: "25px",
                  }}
                >
                <FormikField name="planName" label="Nombre de plan" />
                <FormikField name="consultationsPerMonth" label="Consultas por mes" />
                <FormikField name="familyMembers" label="Cantidad de miembros" />
                <FormikField name="price" label="Precio" />
                <FormikField name="priceMember" label="Precio por miembro adicional" />
                  <>
              </>
                </div>
                <div style={{ display: "flex"}}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#DA2037",
                    margin: "30px 10px 0 0",
                    borderRadius: "20px",
                    texttransform: "capitalize",
                  }}
                  onClick={() => setOpen(false)}
                >
                  Cancelar
                </Button>
                <Button
                  disabled={Object.keys(errors).length != 0}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#002c54",
                    margin: "30px 10px 0 0",
                    borderRadius: "20px",
                    texttransform: "capitalize",
                  }}
                  onClick={() => {
                    handleClose(values)
                  }}
                >
                  Guardar
                </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </BaseModal>
    </div>
  );
};
