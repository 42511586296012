import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "../styles/MuiDataTableStyle";
import { PlanTable } from "../components/PlanTable";
import { getPlans } from "../services/plansService";
import { useStateAuthValue } from "../context/AuthState";
import { useToast } from "../context/Toast/ToastProvider";
import { showToast, ToastType } from "../utils/ToastUtil";
import { LoadingModal } from "../components/Modals/LoadingModal";
import {
  deleteEmailBlacklist,
  getEmailBlacklist,
  saveEmailBlacklist,
} from "../services/configurationService";
import EditIcon from "@mui/icons-material/Edit";
import MUIDataTable from "mui-datatables";
import { downloadOptions, textLabels } from "../utils/muiTableOptions";
import { Delete, Search } from "@mui/icons-material";
import { ClearIcon } from "@mui/x-date-pickers";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { BaseModal } from "../components/Modals/BaseModal";
import { set } from "date-fns";

const EmailBlacklist = () => {
  const [{ userToken }] = useStateAuthValue();
  const [data, setData] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [emailSelected, setEmailSelected] = useState({ id: "", email: "" });
  const [error, setError] = useState();
  const [openCreateNewModal, setOpenCreateNewModal] = useState(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const [lastTextSearch, setLastTextSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const toastDispatch = useToast();
  const displayError = (msg) =>
    showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);
  const displaySucces = (msg) =>
    showToast(toastDispatch, msg, ToastType.SUCCESS, ToastType.SUCCESS);
  const getData = async (email) => {
    const response = await getEmailBlacklist(userToken, email);
    setData(response);
  };

  function parseEmails(input) {
    // Separamos los correos en base a comas, espacios o saltos de línea
    const emailArray = input.split(/[\s,]+/);

    return emailArray;
  }

  const saveData = async (emailBlackList) => {
    if (emailBlackList.id) {
      await saveEmailBlacklist(userToken, emailBlackList).then((thenRes) => {
        console.log("thenRes: ", thenRes);
        if (thenRes.status == 200) {
          displaySucces("Email editado correctamente");
          getData("");
          setOpenCreateNewModal(false);
        } else {
          displayError(`Error al editar email:\n${emailBlackList.email}`);
          setError(
            thenRes.response
              ? thenRes.response.data
              : "Error al guardar, intente mas tarde"
          );
        }
      });
    } else {
      const emails = parseEmails(emailBlackList.email);
      let hasError = false;

      const saveAllEmails = emails.map(async (email) => {
        let _emailBlackList = { id: "", email };

        try {
          const thenRes = await saveEmailBlacklist(userToken, _emailBlackList);
          if (thenRes.status === 200) {
            displaySucces("Email agregado correctamente");
            getData("");
          } else {
            hasError = true;
            displayError(`Error al guardar email:\n${email}`);
            setError(
              thenRes.response
                ? thenRes.response.data
                : "Error al guardar, intente más tarde"
            );
          }
        } catch (error) {
          hasError = true;
          displayError(`Error al guardar email:\n${email}`);
          setError(
            error.response
              ? error.response.data
              : "Error al guardar, intente más tarde"
          );
        }
      });

      Promise.all(saveAllEmails).then(() => {
        if (!hasError) setOpenCreateNewModal(false);
      });
    }
  };

  const deleteData = async (emailBlackList) => {
    await deleteEmailBlacklist(userToken, emailBlackList).then((thenRes) => {
      console.log("thenRes: ", thenRes);
      if (thenRes.status == 200) {
        displaySucces("Email eliminado correctamente");
        getData("");
      } else {
        displayError("Error al eliminar email");
      }
      setOpenConfirmDeleteModal(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getData(textSearch.trim());
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);
  const handleSearchKeyDown = (event) => {
    if (event.key === "Enter") {
      // Aquí defines lo que quieres que pase al presionar "Enter"
      //event.target.blur();
      getData(textSearch?.trim());
      setLastTextSearch(textSearch?.trim());
    }
  };
  const customSearchRender = (
    searchText,
    handleSearch,
    hideSearch,
    options
  ) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton
          onClick={() => {
            getData(textSearch?.trim());
            setLastTextSearch(textSearch?.trim());
          }}
        >
          <Search></Search>
        </IconButton>
        <TextField
          variant="standard"
          value={searchText}
          onChange={(event) => handleSearch(event.target.value)}
          onKeyDown={handleSearchKeyDown}
          placeholder="Search..."
          InputProps={{
            "aria-label": "Search",
          }}
        />
        <IconButton
          onClick={() => {
            handleSearch("");
            setLastTextSearch("");
            getData("");
          }}
        >
          <ClearIcon />
        </IconButton>
        {!(!lastTextSearch || lastTextSearch.trim() === "") && (
          <Box
            sx={{
              background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
              color: "white",
              fontWeight: "bold",
              padding: "10px",
              borderRadius: "15px",
            }}
          >
            {lastTextSearch}
          </Box>
        )}
      </div>
    );
  };
  const columns = [
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "acciones",
      label: "Acciones",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div className="icons-container">
              <EditIcon
                id={data[dataIndex].id}
                className="icon edit-icon"
                onClick={() => {
                  setError();
                  setEmailSelected(data[dataIndex]);
                  setOpenCreateNewModal(true);
                }}
              />
              <Delete
                id={data[dataIndex].id}
                className="icon delete-icon"
                onClick={() => {
                  setEmailSelected(data[dataIndex]);
                  setOpenConfirmDeleteModal(true);
                }}
              />
            </div>
          );
        },
      },
    },
  ];
  const options = {
    searchOpen: true,
    selectableRows: "none",
    textLabels,
    downloadOptions,
    filterType: "dropdown",
    responsive: "standard",
    draggableColumns: { enabled: false },
    enableNestedDataAccess: ".",
    page: currentPage,
    count: totalPages,
    serverSide: true,
    pagination: false,
    elevation: 0,
    customSearchRender,
    onSearchChange: (searchQuery) => {
      if (searchQuery == null) {
        setTextSearch("");
      }
    },
    onTableChange: (action, tableState) => {
      if (action === "search") {
        setTextSearch(tableState.searchText);
      }
    },
    customToolbar: () => {
      return (
        <Tooltip
          title="Agregar mail"
          onClick={() => {
            setError();
            setEmailSelected({ email: "", id: "" });
            setOpenCreateNewModal(true);
          }}
        >
          <IconButton>
            <AddCircleIcon></AddCircleIcon>
          </IconButton>
        </Tooltip>
      );
    },
  };
  return (
    <ThemeProvider theme={MuiTheme}>
      <div className="users">
        <div className="users-table-container">
          <Box
            sx={{
              background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
              padding: "10px",
              borderRadius: "15px",
              color: "white",
              height: "80px",
              display: "flex",
              alignItems: "center",
              width: "90%",
              position: "relative",
              bottom: "25px",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: " 700",
                letterSpacing: " 1px",
              }}
            >
              Listado de email bloqueados
            </Typography>
          </Box>
          <ThemeProvider theme={MuiTheme}>
            <Box
              sx={{
                width: "100%",
                "& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-head": {
                  span: {
                    justifyContent: "center",
                  },
                },
              }}
            >
              <MUIDataTable
                title=""
                data={data}
                columns={columns}
                options={options}
              />
            </Box>
          </ThemeProvider>
        </div>
      </div>
      {loading && <LoadingModal />}
      <BaseModal open={openCreateNewModal} setOpen={setOpenCreateNewModal}>
        <Box
          sx={{
            //background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
            padding: "10px",
            borderRadius: "15px",
            color: "black",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: "100%",
            position: "relative",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: " 700",
              letterSpacing: " 1px",
            }}
          >
            {emailSelected.id ? "Editar email" : "Agregar email"}
          </Typography>

          <TextField
            variant="standard"
            value={emailSelected.email}
            onChange={(event) => {
              setError();
              setEmailSelected({
                ...emailSelected,
                email: event.target.value,
              });
              // handleSearch(event.target.value);
            }}
            //onKeyDown={handleSearchKeyDown}
            placeholder="Search..."
            InputProps={{
              "aria-label": "Search",
            }}
            children={"Error"}
          />
          {error && (
            <p style={{ color: "red", fontWeight: "bold" }}> {error} </p>
          )}

          <Button
            variant="contained"
            onClick={() => {
              if (!emailSelected.email || emailSelected.email.trim() === "") {
                setError("El email no puede ser un campo vacio.");
              } else {
                saveData(emailSelected);
              }
            }}
          >
            GUARDAR
          </Button>
        </Box>
      </BaseModal>
      <Dialog
        open={openConfirmDeleteModal}
        onClose={() => {
          setOpenConfirmDeleteModal(false);
        }}
        style={{ borderRadius: "10px" }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Eliminar email de la lista?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Una vez borrado el email estara habilitado, desea continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenConfirmDeleteModal(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              deleteData(emailSelected);
            }}
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default EmailBlacklist;
