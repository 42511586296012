import * as React from "react";
import { BaseModal } from "./BaseModal";
import { InfinitySpin } from "react-loader-spinner";

export const LoadingModal = () => {
  return (
    <BaseModal open={true} setOpen={() => {}}>
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        }}>
            <h3 style={{ fontWeight: "300"}}>Cargando...</h3>
            <InfinitySpin width="200" height="150" color="#002c54" />
        </div>
    </BaseModal>
  );
};
