import * as React from "react";
import Button from "@mui/material/Button";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { BaseModal } from "./BaseModal";
import { useState } from "react";
import { useToast } from "../../context/Toast/ToastProvider";
import { ToastType, showToast } from "../../utils/ToastUtil";
import { useEffect } from "react";
import { useStateAuthValue } from "../../context/AuthState";
import { useRef } from "react";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { importPatients } from "../../services/patientsService";
import { translations } from "./ImportPatientsModalTranslation.js";

export const ImportPatientsModal = ({
  open,
  setOpen,
  financer,
}) => {
  
  const toastDispatch = useToast();
  const showError = (msgError) => {showToast(toastDispatch, msgError, ToastType.ERROR, ToastType.ERROR);};
  const [{ userToken }] = useStateAuthValue();
  const formikSetValues = useRef(null);

  const fields = [
    {
      // Visible in table header and when matching columns.
      label: "Nombre",
      // This is the key used for this field when we call onSubmit.
      key: "firstName",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["firstName", "nombre"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      // example: "Stephanie",
      // Can have multiple validations that are visible in Validation Step table.
      validations: [
        {
          // Can be "required" / "unique" / "regex"
          rule: "required",
          errorMessage: "Nombre requerido",
          // There can be "info" / "warning" / "error" levels. Optional. Default "error".
          level: "error",
        },
      ],
    },
    {
      label: "Apellido",
      key: "lastName",
      alternateMatches: ["apellido", "lastName", "lastname"],
      fieldType: {
        type: "input",
      },
      validations: [
        {
          rule: "required",
          errorMessage: "Apellido requerido",
          level: "error",
        },
      ],
    },
    {
      label: "Correo",
      key: "email",
      alternateMatches: ["email", "mail", "correo"],
      fieldType: {
        type: "input",
      },
      validations: [
        {
          rule: "required",
          errorMessage: "Correo requerido",
          level: "error",
        },
      ],
    },
    {
      label: "Documento",
      key: "idNumber",
      alternateMatches: ["idnumber", "idNumber","documento", "dni", "DNI"],
      fieldType: {
        type: "input",
      },
      validations: [
        {
          rule: "required",
          errorMessage: "Documento requerido",
          level: "error",
        },
      ],
    },
    {
      label: "Plan",
      key: "plan",
      alternateMatches: ["plan"],
      fieldType: {
        type: "input",
        // type: "select",
        // options: [
        //   { label: "Team One", value: "one" },
        //   { label: "Team Two", value: "two" },
        // ],
      },
      validations: [
        {
          rule: "required",
          errorMessage: "Plan requerido",
          level: "error",
        },
      ],
    },
  ];

  const displayError = (msg) =>
  showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);

  const onClose = () => {
    setOpen(false)
  };

  const onSubmit =  (data, file) => {
    setOpen(false)
    let sendData = { data: JSON.stringify(data.validData),
      filename: file.name,
      size: file.size,
      financerId: financer.id
    }
    console.log('sendData');

    console.log(sendData);
    importPatients(userToken, sendData, displayError).then((e) => {
      if (e != null) {
        showToast(
        toastDispatch,
          "Se creo con exito",
          ToastType.SUCCESS,
          ToastType.SUCCESS
        );
      }
    });
  };

  return (
    <div>
      <BaseModal open={open} setOpen={setOpen}>
        <Formik>
          {({ isValid, values, setValues }) => {
            formikSetValues.current = setValues;
            return (
              <ReactSpreadsheetImport isOpen={open} onClose={onClose} onSubmit={onSubmit} fields={fields} translations={translations} />
            );
          }}
        </Formik>
      </BaseModal>
    </div>
  );
};
