import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "../styles/MuiDataTableStyle";
import { ClientTable } from "../components/clientTable";
import { getPatients } from "../services/patientsService";
import { getNotifications } from "../services/notificationsService";
import { useStateAuthValue } from "../context/AuthState";
import { useToast } from "../context/Toast/ToastProvider";
import { showToast, ToastType } from "../utils/ToastUtil";
import { LoadingModal } from "../components/Modals/LoadingModal";
import { NotificationTable } from "../components/notificationTable";
import { useLocation } from "react-router-dom";

const Notifications = () => {
  const [{ userToken }] = useStateAuthValue();
  const [data, setData] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const toastDispatch = useToast();
  const displayError = (msg) => showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);
  let location = useLocation().search.split('?param=')[1];
  const getNotificationsData = async (params) => {
    const response = await getNotifications(userToken, displayError, params);
    setData(response.notifications);
    setDataLength(response?.notifications?.length)
    setCurrentPage(response?.currentPage);
    setTotalPages(response?.totalPages > 1 && response?.currentPage === 0 ? -1 : response?.totalItems);
  }
  useEffect(() => {
    let params = {}
    params.status = 'ERROR'
    params.meanTypes = ['WHATSAPP', 'EMAIL']
    params.event = location.toUpperCase()
    setLoading(true);
    getNotificationsData(params)
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  return (
    <ThemeProvider theme={MuiTheme}>
      <div className="users">
        <div className="users-table-container">
          <Box
            sx={{
              background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
              padding: "10px",
              borderRadius: "15px",
              color: "white",
              height: "80px",
              display: "flex",
              alignItems: "center",
              width: "90%",
              position: "relative",
              bottom: "25px",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: " 700",
                letterSpacing: " 1px",
              }}
            >
              Listado de notificationes
            </Typography>
          </Box>

          <NotificationTable
            data={data}
            getNotificationsData={getNotificationsData}
            setLoading={setLoading}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            dataLength={dataLength}
            currentPage={currentPage}
            event={location.toUpperCase()}
          />

        </div>
      </div>
      {loading && <LoadingModal />}
    </ThemeProvider>
  );
};

export default Notifications;
