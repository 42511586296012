import React, { createContext, useContext, useReducer } from 'react';

const initialState = {
  userData: {},
  userToken: localStorage.getItem("token"),
  userRole: localStorage.getItem("role")
};

const AuthReducer = (state, action) => {
  switch (action.type) {
    case 'LOG_IN':
      return {
        ...state,
        userData : action.userData,
        userToken : action.userToken,
        userRole: action.userRole
      };
    case 'LOG_OUT':
      return {
        ...state,
        userData : {},
        userToken : null,
        userRole: null
      };

    default:
      return state;
  }
};

const AuthContext = createContext();

export const AuthState = ({ children }) => (
  <AuthContext.Provider value={useReducer(AuthReducer, initialState)}>{children}</AuthContext.Provider>
);

export const useStateAuthValue = () => useContext(AuthContext);
