import axios from "axios";
import decode from "jwt-decode";

import { roles } from "../constants/roles";

export const login = async (values, displayError) => {
  return await axios
    .post(process.env.REACT_APP_BASE_URL + "/api/authenticate", {
      username: values.username,
      password: values.password,
      rememberMe: true,
    })
    .then((response) => {
      response.data.role = decode(response.data.id_token).auth;
      if (
        response.data.role === roles.ROLE_OPERATOR ||
        response.data.role === roles.ROLE_SELLER ||
        response.data.role === roles.ROLE_GIGARED
      ) {
        return response.data;
      } else {
        displayError("Usuario no autorizado.");
      }
    })
    .catch((error) => {
      if (error.response.status === 401) {
        displayError("Las credenciales son incorrectas.");
      } else {
        displayError("Login error: " + error.message);
      }
    });
};

export const checkAuth = async (token) => {
  return await axios
    .get(process.env.REACT_APP_BASE_URL + "/api/account", {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      try {
        if (response.status == 200) {
          var decodedToken = decode(token);
          var expirationTimeInSeconds = decodedToken.exp;
          var expirationTimeInMillis = expirationTimeInSeconds * 1000;
          var currentTimeInMillis = Date.now();

          if (currentTimeInMillis > expirationTimeInMillis) {
            window.location.href = "/";
            localStorage.clear();
          }
        }
      } catch (error) {
        window.location.href = "/";
        localStorage.clear();
      }
    })
    .catch(() => {
      window.location.href = "/";
      localStorage.clear();
    });
};
