import React, { createContext, useContext, useReducer } from 'react';

const initialState = {
  reports: null,
};

const ReportReducer = (state, action) => {
  return {
    ...state,
    reports: action.reports,
  };
};

const ReportContext = createContext();

export const ReportsState = ({ children }) => (
  <ReportContext.Provider value={useReducer(ReportReducer, initialState)}>{children}</ReportContext.Provider>
);

export const useStateReportValue = () => useContext(ReportContext);
